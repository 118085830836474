.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, .5) !important;
    z-index: 1400;
}

.confirm {
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        color: $color-blue-dark;
        background-color: #FFFFFF;
        font-family: $font-open-sans;
        border-radius: 5px;
        max-width: 600px;

        > h6 {
            font-family: $font-open-sans-bold;
            font-size: 20px;
            text-align: center;
        }

        > p {
            font-size: 18px;
            text-align: center;
        }

        button {
            margin-top: 20px;
        }

        &__buttons {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-top: 20px;
            margin-bottom: 2px;

            button {
                &:first-of-type {
                    margin-right: 10px;
                }
            }
        }
    }

}
