
.image-upload {
    display: flex;
    margin: 0 0 20px 0;
    padding: 10px;

    input {
        &[type='file'] {
            display: none;
        }
    }

    > img {
        &.product-image {
            width: 120px;
            height: 120px;
            margin: 0 20px 0 0;
        }
    }


    > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        button {
            margin: 0 0 10px 0;
        }

        p {
            margin: 0;
            font-size: 11px;
            color: $color-grey;
        }

        .field-error {
            margin-top: 5px;
            color: red;
        }
    }

}
