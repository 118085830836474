.show-hide-field {
    width: 549px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px $color-shadow;

    &__header {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &__left {
            display: flex;
            justify-content: flex-start;

            > img {
                display: block;
                margin-top: auto;
                margin-bottom: auto;
                margin-left: 16px;
                margin-right: 16px;
                grid-column: 1;
                grid-row: 1;
            }

            > p {
                width: max-content;
                color: $color-blue-dark;
                font-family: $font-open-sans-semibold;
                text-align: left;
                margin-bottom: 0;
                font-size: 18px;
                grid-column: 2;
                grid-row: 1;
                justify-self: flex-start;
            }
        }

        > div {
            justify-self: flex-end;
            margin-right: 11px;
            grid-column: 3;
        }

        &__button {
            &--expanded {
                transform: scaleY(-1);
            }
        }

    }

    &__content {
        padding: .5em;
        font-family: $font-open-sans-light;
        font-size: .95rem;
        color: $color-blue-dark;
        border-top: 1px solid $color-light-grey;

        &__tags {
            margin-top: .5rem;
            font-size: .8rem;
            font-style: italic;
        }

        > ul {
            margin-top: .5rem;
            margin-bottom: .5rem;
            list-style-position: inside;

            > li {
                &::marker {
                    color: $color-blue;
                }
            }
        }
    }
}
