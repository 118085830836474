/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.highlight-tooltip {
    .tooltip-inner {
        max-width: 300px;
        font-family: $font-open-sans;
        white-space: pre-line;
        color: $color-blue-dark !important;
        background-color: #ffffff !important;
        border: 1px solid $color-blue-dark !important;
    }
}
