/**
 *
 * @Copyright 2021 VOID SOFTWARE, S.A.
 *
 */

.discount-box {
    position: absolute;
    top: 10px;
    left: 10px;
    float: left;
    border-radius: 3px;
    color: #FFFFFF;
    font-family: $font-open-sans-bold;
    font-size: 16px;
    padding: 2px 10px;
    width: 55px;
    display: flex;
    justify-content: center;

    &--green {
        background-color: $color-discount-green;
    }

    &--yellow {
        background-color: $color-discount-yellow;
    }

    &--orange {
        background-color: $color-discount-orange;
    }
}