.terms-screen {
    margin-top: $top-bar-height;

    .nav {
        margin-top: 3rem;
        top: calc(#{$top-bar-height} + 10px);
    }

    .nav-link {
        font-family: $font-open-sans;
        color: $color-blue;
        border-radius: 0 !important;
        border-bottom: 1px solid $color-light-grey;

        &.active {
            font-family: $font-open-sans-semibold;
            background-color: unset !important;
            color: $color-blue-dark !important;
        }

        &:hover {
            color: $color-blue-dark;
        }
    }

    &__content {
        padding: 0 1rem;
        font-family: $font-open-sans;
        font-size: 15px;
        text-align: center;

        h4 {
            font-family: $font-open-sans-semibold;
            font-size: 22px;
            text-align: center;
        }

        .text-container {
            margin: 1rem 0;
            text-align: left;

            > img {
                width: 100%;
                max-width: 900px;
            }

            &__index {
                padding-left: 1rem;
            }
        }
    }
}