$top-bar-height: 120px;
$footer-height: 460px;
$footer-height-medium: 590px;
$footer-height-mobile: 630px;

// COLORS
$color-blue: #24a6df;
$color-blue-dark: #23294e;
$color-grey-dark: #414C5D;
$color-grey: #838D9A;
$color-light-grey: #E8E8E8;
$color-lighter-grey: #AAAAAA1A;
$color-shadow: #8B8DA033;
$color-blue-link: #007bff;

$color-red: #cc0000;
$color-light-red: #ff6666;

$color-whatsapp-green: #25D366;
$color-whatsapp-green-dark: #075E54;

$color-discount-green: #a1c347;
$color-discount-yellow: #FDC405;
$color-discount-orange: #FA9B2B;

// fonts
$font-open-sans: 'OpenSansRegular', sans-serif;
$font-open-sans-light: 'OpenSansLight', sans-serif;
$font-open-sans-bold: 'OpenSansBold', sans-serif;
$font-open-sans-semibold: 'OpenSansSemibold', sans-serif;

// z-indexes
$blackout-z-index: 1031;
$sidebar-z-index: 1032;
$home-screen-promo-z-index: 1;
$product-screen-promo-z-index: 2;

//home category selector
$largest-card-height: 682px;
$tall-card-height: 448px;
$short-card-height: 212px;
$tall-card-height-mobile: 210px;
$short-card-height-mobile: 100px;
