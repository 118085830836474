.app_screen {
    height: 100%;

    .page-pretitle {
        text-align: center;
        font-family: $font-open-sans-light;
        font-size: 20px;
        color: $color-blue-dark;
    }

    .page-title {
        font-family: $font-open-sans-bold;
        font-size: 20px;
        color: $color-blue-dark;
        margin-top: 2rem;

        &.with-border {
            padding-bottom: 1rem;
            padding-left: 0.825rem;
            padding-right: 0.825rem;
            border-bottom: 2px solid $color-blue-dark;
            text-align: center;
        }
    }

    .information-text {
        font-family: $font-open-sans-semibold;
        font-size: 20px;
        color: rgba(#000000, 0.7);
        width: 100%;
        max-width: 750px;
        text-align: center;
        padding: 1rem 0.825rem;
    }

    &__centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > p {
            font-family: $font-open-sans-light;
            color: $color-blue-dark;
            font-size: 20px;
        }

        &.margin-top-40 {
            margin-top: 40px;
        }

        &.margin-top-bar {
            margin-top: $top-bar-height;
        }

        .confirm-user-screen {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            border: 2px solid $color-light-grey;
            border-radius: 10px;
            padding: 15px 30px;

            > img {
                margin-bottom: 25px;
            }

            > button {
                margin-top: 20px;
                margin-bottom: 15px;
            }
        }

        .register-form-content {
            width: 100%;
            max-width: 750px;
            padding-top: 20px;
            margin-top: 20px;
            margin-bottom: 77px;


            .form-group {
                > input {
                    border-radius: 6px;
                    font-family: $font-open-sans;
                    letter-spacing: .48px;

                    &::placeholder {
                        color: $color-grey;
                    }
                }
            }

            @media (max-width: 400px) {
                box-shadow: 10px 10px 5px $color-shadow;
                padding: 1em 1em 3em 1em;
                margin-bottom: 1em;
                border-radius: 10px;

                .terms-checkbox-container {
                    p {
                        color: $color-grey-dark !important;
                    }
                }
            }

            @media (min-width: 400px) {
                .terms-checkbox-container {
                    p {
                        color: $color-grey !important;
                    }
                }
            }

            .terms-checkbox-container {
                font-size: .9rem;

                input {
                    margin-top: .2em !important;
                }

                p.error-field {
                    color: red !important;
                    font-size: 13px;
                    font-family: $font-open-sans-bold;
                }

                a {
                    color: $color-blue;
                    font-size: .8rem !important;
                }
            }

            .button-wrapper {
                width: 100%;
                display: inline-flex;
                justify-content: center;

                > button {
                    display: flex;
                    justify-content: center;
                    width: 320px;
                    height: 52px;
                    font-size: 18px;
                    border-radius: 40px;
                    font-family: $font-open-sans-semibold;
                    text-align: center;
                    padding: 1em 0 1em 0;
                }
            }

            &.success-register {
                text-align: center;

                .success-button-wrapper {
                    margin-top: 2rem;
                    width: 100%;
                    display: inline-flex;
                    justify-content: center;
                }
            }
        }

        .login-form-content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 20px;
            padding-top: 20px;
            width: 100%;
            max-width: 450px;

            @media (max-width: 768px) {
                padding: 1em;
                box-shadow: 5px 5px 5px $color-shadow;
                border-radius: 20px;
            }

            &__button-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                > :nth-child(1) {
                    margin-top: 32px;
                }

                &__divider {
                    width: 60%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 16px;
                    margin-bottom: 16px;

                    > hr {
                        margin: 0 16px 4px 16px;
                        width: 80px;
                    }

                    > p {
                        font-family: $font-open-sans;
                        color: $color-grey;
                    }
                }

                > button {
                    min-width: 50%;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;

                    @media (max-width: 768px) {
                        font-family: $font-open-sans;
                        width: 95%;
                        height: 50px;
                        font-size: 18px;
                    }
                }
            }

            &.recover-password {
                border-top: none;
                padding-top: 0;
                margin-bottom: 30px;
            }

            > .form-group {
                width: 100%;
                margin-bottom: 10px;

                > input {
                    border-radius: 6px;
                    font-family: $font-open-sans;
                    letter-spacing: .48px;

                    &::placeholder {
                        color: $color-grey;
                    }
                }
            }

            .form-error-container {
                align-self: center;
            }

            .align-left {
                display: inline-flex;
                font-size: 1rem;
                font-family: $font-open-sans;
                color: $color-blue-dark;
                margin-bottom: 10px;
                width: 100%;
                justify-content: flex-start;

                a {
                    text-align: left;
                    font-size: 15px;
                    text-decoration: underline;
                    font-family: $font-open-sans-semibold;
                    color: $color-grey;
                    margin-top: .5em;
                    margin-left: 10px;

                    @media (max-width: 768px) {
                        font-family: $font-open-sans;
                        font-size: 10px;
                        letter-spacing: .48px;
                        margin-top: -5px;
                    }
                }
            }

            .inline {
                display: inline-flex;
                font-size: 1rem;
                font-family: $font-open-sans;
                color: $color-blue-dark;
                margin-bottom: 10px;
                width: 100%;
                justify-content: center;

                a {
                    font-size: 1rem;
                    font-family: $font-open-sans-semibold;
                    color: $color-blue-dark;
                }
            }
        }

        .login-form-register {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-content: center;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;

            @media (max-width: 768px) {
                width: 100%;
                max-width: 450px;
                padding: 1em;
                box-shadow: 5px 5px 5px $color-shadow;
                border-radius: 20px;
                margin-top: 60px;
            }

            > img {
                height: 30px;
                margin-bottom: 30px;

                @media (max-width: 768px) {
                    height: 25px;
                    margin-bottom: 0;
                }
            }

            &__line1 {
                font-family: $font-open-sans-semibold;
                color: $color-blue-dark;
                font-size: 20px;

                @media (max-width: 768px) {
                    font-family: $font-open-sans-bold;
                    margin: 0;
                }
            }

            &__line2 {
                font-family: $font-open-sans-light;
                font-size: 20px;
                margin-bottom: 30px;
            }

            > * {
                display: block;
                margin-top: 1em;

                @media (max-width: 768px) {
                    margin: 0;
                }
            }

            > button {
                align-self: center;
                width: 225px;
                margin-bottom: 30px;

                @media (max-width: 768px) {
                    font-family: $font-open-sans;
                    width: 95%;
                    height: 50px;
                    font-size: 18px;
                    margin-bottom: 10px;
                    margin-top: 20px;
                }
            }

            > div {
                margin-top: 1em;
                margin-bottom: 1em;
                color: $color-blue-dark;
                font-size: 1rem;
            }
        }
    }

    &__stores {
        .categories-select-container {
            margin-top: 2rem;
            padding: 0 1rem;

            .form-group {
                width: 450px;
            }
        }

        .products-search-container {
            padding: 0 0.5rem;

            .search-field {
                min-width: 120px;
                width: 450px;
            }
        }

        &.all-products {
            margin-bottom: 2rem;

            .paging {
                font-family: $font-open-sans-bold;
            }

            .top-container {
                width: 100%;
                display: inline-flex;
                justify-content: center;
                background-color: #24a6df;
            }

            .card {
                border: none;
                font-family: $font-open-sans-semibold;
                font-size: 13px;
                height: 100%;
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;

                .card-body {
                    flex: unset;
                    padding: 0.5rem;
                }

                .product-title {
                    color: $color-blue-dark;
                    line-height: 15px;
                    margin-bottom: 0.25rem !important;
                    flex: 1;
                }

                .price {
                    color: $color-blue;
                    font-size: 15px;
                }

                .old-price {
                    color: rgba($color-blue, 0.3);
                    font-size: 15px;
                    text-decoration: line-through;
                }

                img {
                    border: 1px solid $color-light-grey;
                    border-radius: 0 !important;
                }
            }
        }

        @media (min-width: 992px) {
            .col-lg-4 {
                width: 303px;
                max-width: 303px;
                padding: 0;

                .card {
                    width: 303px;
                    height: 100%;
                }

                img {
                    height: 300px !important;
                    width: 300px;
                }
            }
        }

        @media (min-width: 768px) and (max-width: 992px) {
            .col-md-6 {
                width: 283px;
                max-width: 283px;
                padding: 0;

                .card {
                    width: 283px;
                    height: 100%;
                }

                img {
                    height: 280px;
                    max-height: 280px;
                }
            }
        }

        @media (max-width: 768px) {
            .col-12 {
                width: 253px;
                max-width: 253px;
                padding: 0;

                .card {
                    width: 253px;
                    height: 100%;
                }

                img {
                    height: 250px;
                    max-height: 250px;
                }
            }
        }

        @media (min-width: 340px) and (max-width: 575px) {
            .col-12 {
                width: 303px;
                max-width: 303px;
                padding: 0;

                .card {
                    width: 303px;
                }

                img {
                    height: 300px;
                    max-height: 300px;
                }
            }
        }

        .store-card-item {
            cursor: pointer;
            margin: 0.9rem 0.9rem;

            .product-store {
                font-family: $font-open-sans-semibold;
                font-size: 14px;
            }
        }
    }

    &__store_screen {
        width: 100%;
        min-height: calc(100vh - #{$top-bar-height} - #{$footer-height});
        position: relative;

        .whatsapp-container {
            position: absolute;
            bottom: 20px;
            right: 20px;

            img {
                width: 70px;
                height: 70px;
            }
        }

        .top-container {
            margin-top: 2rem;
            text-align: center;

            p {
                font-family: $font-open-sans-semibold;
                font-size: 16px;
            }

            .bold {
                font-family: $font-open-sans-bold;
            }
        }

        .left-container {
            min-height: 100%;
            display: flex;
            flex-direction: column;
        }

        .blue-container {
            background-color: rgba($color-blue, 0.04);
            padding: 1rem 1rem 1rem 155px;
            font-size: 14px;
            flex: 1 1 auto;

            &.align-center {
                text-align: center;

                img {
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            @media (min-width: 700px) and (max-width: 991px) {
                padding: 1rem 1rem 1rem 220px;
            }

            @media (max-width: 700px) {
                padding: 1rem 1rem 1rem 120px;
            }

            @media (max-width: 540px) {
                padding: 2rem 1rem 1rem 70px;
            }

            @media (max-width: 400px) {
                padding: 1rem 1rem 1rem 1rem;
            }

            .title {
                font-family: $font-open-sans-bold;
                color: $color-blue-dark;
                font-size: 35px;
                line-height: 35px;
                margin-bottom: 2px;
                margin-top: 10px;
                cursor: pointer;
            }

            .subtitle {
                font-family: $font-open-sans-semibold;
                color: $color-blue;
            }

            .description {
                font-family: $font-open-sans;
                line-height: 24px;
                margin: 1rem 0;
            }

            .info-item {
                display: flex;
                flex-direction: column;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 0.825rem;

                .info-title {
                    font-family: $font-open-sans;
                    color: $color-blue;
                }

                .info {
                    font-family: $font-open-sans-semibold;
                    color: $color-blue-dark;

                    &.underlined {
                        text-decoration: underline;
                    }
                }

                .schedule-hours {
                    color: $color-blue-dark;
                    padding-bottom: 10px;
                }
            }

            img {
                width: 75px;
                height: 75px;
                cursor: pointer;
            }
        }

        .product-details.container-fluid {
            height: unset;
        }

        @media (max-width: 603px) {
            .product-details.container-fluid {
                margin-bottom: 15px;
            }
        }

        @media (max-width: 283px) {
            .product-details.container-fluid {
                margin-bottom: 35px;
            }
        }

        .product-details {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            &__bottom-container {
                margin-top: 50px;
                display: flex;

                &--mobile {
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    margin-top: 20px;
                }

                &__logistics {
                    width: 549px;
                    margin-right: 88px;

                    @media (max-width: 992px) {
                        width: 100%;
                        margin-right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 30px;

                        > * {
                            width: 90vw;
                        }
                    }

                }

                &__taglines {
                    width: 361px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    @media (max-width: 992px) {
                        width: 100%;
                        margin-right: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                    }

                    > :last-child {
                        margin-bottom: 0;
                    }

                    &__row {
                        display: flex;
                        justify-content: flex-start;
                        align-content: center;
                        width: 80%;
                        margin-bottom: 25px;

                        @media (max-width: 992px) {
                            width: 95%;
                        }

                        &__icon {
                            display: block;
                            width: 60px;
                            height: 60px;
                            background-color: rgba($color-blue, 0.2);
                            border-radius: 50%;
                            padding: 15px;

                            > img {
                                width: 30px;
                                height: 30px;
                            }
                        }

                        &__text {
                            margin-left: 35px;
                            color: $color-blue-dark;

                            > h5 {
                                font-family: $font-open-sans-bold;
                                font-size: 15px;
                            }

                            > p {
                                font-family: $font-open-sans-light;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }

            &__top-container {
                width: 1123px;
                display: flex;
                justify-content: flex-start;
                align-self: center;
                margin-top: $top-bar-height;
                margin-bottom: 1rem;

                > h5, h4 {
                    margin-bottom: 4px;
                }

                @media (max-width: 992px) {
                    width: 100%;
                }

                &__links {
                    display: flex;
                    font-family: $font-open-sans;
                    font-size: 14px;
                    letter-spacing: 0.42px;
                    color: $color-grey;

                    &__line1 {
                        color: $color-grey;
                        font-size: 13px;

                        > :nth-child(1) {
                            margin-left: 0;
                        }

                        > a {
                            margin: 0 5px;
                        }

                        > * {
                            color: $color-grey;
                        }

                        > :last-child {
                            color: $color-blue;
                            overflow-wrap: normal;
                        }
                    }

                    > * {
                        color: $color-grey;
                    }

                    > p {
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &__product-container {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                align-self: center;
                font-family: $font-open-sans;
                color: $color-blue-dark;

                &--mobile {
                    width: 100%;
                }


                &__header {
                    > h5 {
                        color: $color-blue;
                        font-family: $font-open-sans-bold;
                        text-transform: uppercase;
                        font-size: 12px;
                        letter-spacing: 1.2px;
                    }

                    > h4 {
                        color: $color-blue-dark;
                        font-family: $font-open-sans-semibold;
                        font-size: 24px;
                        letter-spacing: 0.72px;
                    }

                    > div {
                        p {
                            font-size: 0.8em;
                            color: $color-grey;
                        }
                    }

                }

                @media (max-width: 569px) {
                    display: flex;
                    flex-direction: column;
                }

                &__information-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    padding: 1rem 0 0 0;
                    width: 100%;

                    &--mobile {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 20px 0;
                        width: 100%;
                        align-items: center;
                    }

                    @media (max-width: 569px) {
                        padding: 1rem 0 0 0;

                        .button {
                            font-size: 14px;
                            height: 30px;
                        }
                    }

                    @media (max-width: 367px) {
                        .w-100 {
                            display: flex !important;
                            flex-direction: column !important;
                            align-items: flex-start;

                            .button:first-child {
                                margin-top: 10px;
                            }
                        }
                    }

                    @media (max-width: 605px) {
                        padding: 0.5rem 0 0 0.5rem;
                    }

                    .name {
                        flex: 1;
                        padding-right: 0.5rem;
                        display: flex;
                        justify-content: flex-start;
                        flex-direction: column;
                    }

                    h4 {
                        font-family: $font-open-sans-bold;
                    }

                    .brand {
                        font-size: 13px;
                        color: $color-grey;
                    }

                    .product-variants {
                        padding: 20px 0;


                        .detail {
                            font-size: 15px;
                        }

                        .card {
                            border: 2px solid $color-light-grey;
                            border-radius: 10px;
                        }

                        .variant-card {
                            width: inherit;
                            height: inherit;
                            overflow: hidden;

                            img {
                                width: inherit;
                                display: block;
                                align-self: center;
                                margin-top: auto;
                                margin-bottom: auto;
                                cursor: pointer;
                                border-radius: 10px;
                            }
                        }
                    }

                    .details {
                        font-size: 14px;
                        padding: 10px 0;

                        .bold {
                            font-family: $font-open-sans-bold;
                        }
                    }

                    &__controls-container {
                        display: flex;
                        flex-direction: column;
                        margin-left: 10px;

                        > h5 {
                            font-family: $font-open-sans-semibold;
                            font-size: 16px;
                            margin-bottom: 0;
                        }

                        @media (max-width: 992px) {
                            margin-top: 30px;
                            margin-left: 0;
                            width: 90%;
                            min-width: 455px;
                        }

                        @media (max-width: 600px) {
                            margin-top: 30px;
                            margin-left: 0;
                            width: 90vw;
                            min-width: 90vw;
                        }

                        &__price {
                            margin-bottom: 20px;
                            color: $color-blue;
                            font-size: 2em;
                            font-family: $font-open-sans-bold;
                            line-height: 1;
                            margin-top: 20px;

                            @media(max-width: 800px) {
                                align-self: flex-start;
                            }

                            > h5 {
                                font-size: 16px;
                                font-family: $font-open-sans-semibold;
                                color: $color-blue-dark;
                                margin-top: 15px;
                            }

                            &__stock-label {
                                margin-left: auto;
                                align-self: center;
                                color: $color-blue-dark;
                                font-size: 16px;
                                font-family: $font-open-sans-light;
                                letter-spacing: 0.48px;
                            }

                            &__values {
                                display: inline-flex;
                                align-items: flex-end;

                                &--old-price {
                                    font-size: 25px;
                                    line-height: 25px;
                                    text-decoration: line-through;
                                    text-decoration-color: $color-blue;
                                    color: $color-blue-dark;
                                    margin-right: 30px;
                                }
                            }
                        }

                        &__style-info {

                            @media (max-width: 992px) {
                                margin-top: 5px;
                            }

                            &__row {
                                width: 400px;
                                display: flex;
                                flex-direction: column;
                                padding-bottom: 15px;
                                justify-content: space-between;
                                font-family: $font-open-sans-semibold;
                                font-size: 16px;

                                @media (max-width: 992px) {
                                   max-width: 80vw;
                                }

                                &__voucher-label {
                                    display: block;
                                    width: 430px;
                                    margin-bottom: 0;

                                    &__container {

                                        > span {
                                            font-family: $font-open-sans-bold;
                                        }

                                        .value {
                                            font-size: 20px;
                                            color: $color-blue;
                                        }
                                    }

                                    > div {
                                        font-family: $font-open-sans-light;
                                        font-size: 14px;
                                    }
                                }

                                &__color-label {
                                    display: flex;
                                    margin-bottom: 0;

                                    > div {
                                        font-family: $font-open-sans-light;
                                    }
                                }

                                > p {
                                    margin-bottom: 15px;
                                }

                                @media (max-width: 992px) {
                                    > span {
                                        margin-left: 0;
                                    }
                                }

                                > span {
                                    color: $color-grey;
                                    text-transform: capitalize;
                                }
                            }
                        }

                        &__controls {

                            > img {
                                margin-left: 10px;
                                filter: saturate(0%) brightness(140%) contrast(82%);
                                cursor: pointer;
                            }

                            .quantity-controls {
                                width: 549px;

                                @media (max-width: 992px) {
                                    width: 180px;
                                }



                                .input-container {
                                    width: 57px;
                                    height: 50px;
                                    font-size: 15px;
                                }

                                .controls {
                                    width: 45px;
                                    height: 50px;

                                    &.plus {
                                        border-top-right-radius: 30px;
                                        border-bottom-right-radius: 30px;
                                    }

                                    &.minus {
                                        border-top-left-radius: 30px;
                                        border-bottom-left-radius: 30px;
                                    }

                                }
                            }

                            > div {
                                > button {
                                    width: 344px;
                                    height: 50px;
                                    border-radius: 30px;
                                    display: flex;
                                    justify-content: center;
                                    text-transform: unset;
                                    margin-left: 2em;
                                    font-size: 20px;

                                    @media (max-width: 992px) {
                                        width: 100%;
                                    }

                                    &:hover {
                                        > img {
                                            filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
                                        }
                                    }

                                    > img {
                                        width: 26px;
                                        height: 26px;
                                        margin-right: .5em;
                                    }
                                }
                            }

                            @media (max-width: 992px) {
                                > div {
                                    width: 100%;

                                    > button {
                                        margin-top: 1em;
                                        margin-left: 0;
                                        display: flex;
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }

                    &__carousel-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 79px;
                        height: 381px;
                        overflow: hidden;
                        margin-right: 15px;

                        @media (max-width: 992px) {
                            width: 100vw;
                            max-width: 100vw;
                            height: auto;
                            flex-direction: row;
                            justify-content: center;
                            margin-right: 0;

                            .slick-slider {
                                max-width: 80vw;
                            }
                        }

                        > img {
                            width: 25px;
                            cursor: pointer;
                        }

                        &__up-arrow {
                            transform: scaleY(-1);

                            @media (max-width: 992px) {
                                transform: rotate(90deg);
                            }
                        }

                        &__down-arrow {
                            @media (max-width: 992px) {
                                transform: rotate(-90deg);
                            }
                        }

                        .slick-slide {
                            margin-top: 1px;
                            cursor: pointer;

                            > div {
                                > div {

                                    @media (max-width: 992px) {
                                        display: flex !important;
                                        justify-content: center;
                                    }

                                    > img {
                                        width: 79px;
                                        height: 79px;
                                        border-radius: 10px;

                                        @media (max-width: 992px) {
                                            width: 100px;
                                            border: 1px solid $color-light-grey;
                                        }

                                        @media (max-width: 600px) {
                                            width: 79px;
                                        }
                                    }
                                }
                            }
                        }

                    }

                    &__img-container {
                        width: 455px;
                        height: 361px;
                        border: 1px solid #838D9A1A;
                        border-radius: 10px;
                        margin-right: 15px;
                        position: relative;

                        .discount-box {
                            z-index: $product-screen-promo-z-index;
                        }

                        @media (max-width: 992px) {
                            width: 100%;
                            max-width: 455px;
                            margin-right: 0px;
                            margin-bottom: 20px;
                        }

                        @media (max-width: 455px) {
                            width: 343px;
                            height: 271px;
                            margin-right: 0px;
                            margin-bottom: 20px;
                        }

                        &__main-img {
                            object-fit: cover;
                        }

                        .info {
                            padding-top: 1rem;
                            font-family: $font-open-sans;
                            font-size: 14px;

                            .bold {
                                font-family: $font-open-sans-semibold;
                                font-size: 17px;
                            }

                            .small {
                                font-family: $font-open-sans-light;
                                font-size: 13px;
                            }
                        }

                        img {
                            position: absolute;
                            top: 0;
                            width: inherit;
                            height: inherit;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }

        .grid-container {
            padding-bottom: 1rem;

            &.create-health-order {
                h5 {
                    font-family: $font-open-sans-semibold;
                }
            }

            &.health-container {
                padding-right: 0;
                padding-top: 1rem;

                .links-container {
                    text-decoration: underline;
                    cursor: pointer;
                }

                .product-column {
                    font-size: 13px;
                    font-family: $font-open-sans-semibold;
                    line-height: 18px;
                    text-align: center;

                    .title {
                        color: $color-blue-dark;
                        margin-bottom: 0.25rem;
                    }

                    .price {
                        color: $color-blue;
                        font-size: 15px;
                        margin-top: 0.5rem;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                    }
                }
            }

            &.store-products {
                .paging {
                    font-family: $font-open-sans-bold;
                }

                .card {
                    border: none;
                    font-family: $font-open-sans-semibold;
                    font-size: 13px;
                    height: 100%;
                    flex: 1 1 auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    cursor: pointer;

                    .card-body {
                        flex: unset;
                        padding: 0.5rem;
                    }

                    .product-title {
                        color: $color-blue-dark;
                        line-height: 15px;
                        margin-bottom: 0.25rem !important;
                        flex: 1;
                    }

                    .price {
                        color: $color-blue;
                        font-size: 15px;
                    }

                    .old-price {
                        color: rgba($color-blue, 0.3);
                        font-size: 15px;
                        text-decoration: line-through;
                    }

                    img {
                        border: 1px solid $color-light-grey;
                        border-radius: 0 !important;
                    }
                }

                @media (min-width: 1500px) {
                    .card {
                        width: 200px;

                        img {
                            align-self: center;
                            width: 200px;
                            height: 200px;
                        }
                    }
                }

                @media (max-width: 1200px) {
                    .card {
                        width: 143px;

                        img {
                            width: 140px;
                            height: 140px;
                        }
                    }
                }

                @media (min-width: 992px) and (max-width: 1100px) {
                    .card {
                        width: 123px;

                        img {
                            width: 120px;
                            height: 120px;
                        }
                    }
                }
            }

            .top-container {
                width: 100%;
                display: inline-flex;
                justify-content: flex-end;
                margin-top: 1rem;
                margin-bottom: 2rem;
                padding: 0 1rem;
            }

            .trash-icon-cell {
                svg {
                    width: 18px;
                    height: 18px;
                    fill: rgba($color-red, 0.8);
                }
            }
        }
    }

    .leaflet-container {
        height: 350px;
        width: 100%;

        @media (max-width: 400px) {
            height: 200px;
            margin-bottom: 2rem;
        }
        @media (max-width: 700px) {
            height: 250px;
        }
        @media (max-width: 432px) {
            margin-bottom: 0.825rem;
        }

        @media (max-width: 300px) {
            margin-bottom: 3rem;
        }
    }

    &__cart {
        margin-bottom: 1rem;
        padding: 0;

        &__links {
            width: 100%;

            > div {

                display: flex;
                font-family: $font-open-sans;
                font-size: 14px;
                letter-spacing: .6px;
                color: $color-grey;

                > a {
                    color: $color-grey;
                }
            }

            &--current {
                color: $color-blue;
            }
        }

        .container {
            width: 1114px;
            max-width: 1114px;
            padding: 0;
        }

        .page-title {
            width: 100%;
            padding-top: 20px;
            margin-bottom: 56px;
            font-family: $font-open-sans-semibold;
            letter-spacing: .75px;
            font-size: 25px;
            text-align: left;

            @media (max-width: 600px) {
                padding-top: 0;
                font-family: $font-open-sans-bold;
                font-size: 18px;
                text-align: left;
                margin-left: 15px;
            }
        }

        .fullwidth-container {
            width: 100%;
            overflow: hidden;
            height: 250px;
            margin-bottom: 40px;
            margin-top: 30px;

            .info-container {
                width: 100vw;
                position: absolute;
                left: 0;
                background-color: $color-lighter-grey;
                padding: 0.25rem 1rem 1rem;
                margin-bottom: 2rem;
                display: flex;
                justify-content: center;
                padding-top: 10px;

                @media (max-width: 992px) {
                    left: 2.5vw;
                    width: 95vw;
                }

                .content {
                    width: 1114px;
                    padding: 6px 0 6px 0;

                    > div {
                        display: flex;
                        justify-content: space-between;
                        font-size: 16px;
                        margin-bottom: 0.25rem;
                        font-family: $font-open-sans-light;
                        margin-top: 0.5rem;
                    }

                    .price-container {
                        font-size: 20px;
                        color: $color-blue-dark;

                        @media (max-width: 992px) {
                            font-size: 18px;
                        }

                        @media(max-width: 600px) {
                            font-size: 18px;
                        }

                        .bold {
                            font-family: $font-open-sans-semibold;

                            @media (max-width: 992px) {
                                font-family: $font-open-sans-bold;
                            }
                        }

                        &__total {
                            color: $color-blue;

                            &__label {
                                text-transform: uppercase;
                                font-family: $font-open-sans-semibold;
                                font-size: 18px;
                            }

                            &__price {
                                display: flex;
                                flex-direction: column;

                                &__number {
                                    font-family: $font-open-sans-bold;
                                    font-size: 30px;
                                }

                                &__tax-label {
                                    font-family: $font-open-sans-light;
                                    font-size: 15px;
                                    letter-spacing: .45px;
                                }
                            }
                        }
                    }

                    .button-wrapper {
                        width: unset;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin-top: 50px;

                        > button {
                            display: flex;
                            justify-content: center;
                            width: 300px;
                            padding: 0 2em 0 2em;

                            @media (max-width: 992px) {
                                height: 50px;
                                font-family: $font-open-sans;
                            }
                        }
                    }

                    .footer-link-wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        > a {
                            color: $color-grey;
                            text-decoration: underline;

                            @media (max-width: 992px) {
                                font-size: 18px;
                                margin-top: 10px;
                                color: $color-grey-dark;
                            }
                        }

                    }

                    .error {
                        padding: 5px;
                        font-family: $font-open-sans-semibold;
                        color: red;
                    }
                }
            }
        }

        .cart-container {

            .warning-message {
                text-align: center;
                margin-bottom: 1rem !important;
            }

            .no-data {
                width: 100%;
                font-size: 22px;
                text-align: center;
                font-family: $font-open-sans-semibold;
            }

            .title {
                font-family: $font-open-sans-semibold;
                font-size: 18px;
                margin-bottom: 5px !important;
            }

            .payment-options {

                .form-check {
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    margin-bottom: 10px;

                    input {
                        width: 15px;
                        height: 15px;
                    }

                    .form-check-label {
                        padding: 0 5px;

                        img {
                            height: 56px;
                        }
                    }
                }

                .extra {
                    margin-bottom: 10px;
                    padding: 0 20px;

                    .bold {
                        font-family: $font-open-sans-semibold;
                    }

                    .form-group {
                        max-width: 200px;
                    }
                }
            }

            .store-cart-container {
                font-family: $font-open-sans;
                font-size: 16px;
                padding: 0;
                max-width: 1114px;

                > hr {
                    margin-top: 30px;
                    margin-bottom: 60px;

                    @media (max-width: 600px){
                        display: none;
                    }
                }

                .line-container {
                    width: 100%;
                    display: inline-flex;

                    @media (max-width: 600px){
                        border-radius: 10px;
                        padding: 10px;
                        box-shadow: 5px 5px 5px $color-shadow;
                    }

                    .controls-container {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        grid-row: 2;
                        grid-column: 1 / span 2;
                        align-items: flex-end;

                        > div {
                            display: flex;
                            align-items: flex-end;

                            .quantity-controls {
                                max-width: 100px;
                                grid-column: 1;
                            }

                            > button {
                                display: flex;
                                justify-content: center;
                                margin-left: 10px;
                                grid-column: 2;
                                justify-self: flex-start;
                                width: 35px;
                                height: 35px;

                                > img {
                                    width: 15px;
                                    height: 25px;
                                }
                            }
                        }

                        > p {
                            width: 100%;
                            margin-bottom: 0px;
                            text-align: right;
                            grid-column: 3;
                        }

                        .bold-blue {
                            font-size: 25px;
                            font-family: $font-open-sans-bold;
                            color: $color-blue-dark;
                        }
                    }


                    @media (max-width: 992px) {
                        padding: 10px;
                        margin-bottom: 10px;
                        box-shadow: 0 5px 5px $color-shadow;
                        border-radius: 5px;
                        display: grid;
                        grid-template-columns: 20% 80%;
                    }

                    img {
                        width: 95px;
                        height: 95px;
                        box-shadow: 0 5px 5px $color-shadow;
                        cursor: pointer;

                        @media (max-width: 992px) {
                            grid-row: 1;
                            grid-column: 1;
                        }

                    }

                    .line-info {
                        padding: 0 1rem;
                        width: 90%;
                        font-size: 17px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;

                        &__top {
                            display: flex;

                            &__text {
                                display: flex;
                                flex-direction: column;
                            }

                            > button {
                                width: 48px;
                                height: 48px;
                                display: flex;
                                align-content: center;
                                justify-content: center;

                                > img {
                                    display: block;
                                    width: 19px;
                                    height: 23px;
                                    margin-top: auto;
                                    margin-bottom: auto;
                                }

                                &:hover {
                                    > img {
                                        filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
                                    }
                                }
                            }
                        }

                        @media (max-width: 992px) {
                            width: 80%;
                            justify-self: flex-end;
                            padding: 0;
                            grid-row: 1;
                            grid-column: 2;
                        }

                        > div {
                            display: flex;
                            justify-content: space-between;

                            > p {
                                text-align: right;
                                width: 200px;
                                margin-bottom: 0;
                                align-self: flex-end;
                            }
                        }

                        .store-name {
                            font-family: $font-open-sans-bold;
                            font-size: 20px;
                            color: $color-blue-dark;
                            text-transform: uppercase;
                            letter-spacing: 0.6px;
                            cursor: pointer;

                            @media (max-width: 600px){
                                margin-top: 5px;
                                margin-bottom: 0;
                                font-size: 12px;
                                color: $color-grey;
                            }
                        }

                        .blue {
                            font-family: $font-open-sans;
                            color: $color-blue;
                        }

                        .controls-container {
                            padding-top: 0.5rem;
                            width: 100%;
                            max-width: 1200px;
                            display: inline-flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;

                            @media (max-width: 800px) {
                                max-width: 100vw;
                            }

                            > p {
                                font-family: $font-open-sans-bold;
                                font-size: 40px;
                                margin-right: 600px;
                            }

                            .quantity-controls {
                                padding-top: 0;
                                width: 147px;
                                height: 46px;

                                .input-container {
                                    width: 57px;
                                    height: 46px;
                                    font-size: 15px;
                                }

                                .controls {
                                    width: 45px;
                                    height: 46px;

                                    &.plus {
                                        border-top-right-radius: 30px;
                                        border-bottom-right-radius: 30px;
                                    }

                                    &.minus {
                                        border-top-left-radius: 30px;
                                        border-bottom-left-radius: 30px;
                                    }
                                }
                            }
                        }

                        .product-title {
                            font-family: $font-open-sans-light;
                            color: $color-blue-dark;
                            font-size: 25px;
                            cursor: pointer;


                            @media (max-width: 992px) {
                                font-family: $font-open-sans-bold;
                                font-size: 18px;
                            }

                            .bonus {
                                color: $color-grey;
                                font-size: 18px;
                            }

                            .attributes {
                                > div {
                                    margin-top: 18px;
                                    width: 180px;

                                    > p {
                                        display: flex;
                                        font-family: $font-open-sans-light;
                                        font-size: 20px;
                                        color: $color-grey;
                                        align-items: center;
                                        margin-bottom: 0;


                                        @media (max-width: 992px) {
                                            font-family: $font-open-sans;
                                            letter-spacing: .48px;
                                            color: $color-blue-dark;
                                            font-size: 18px;
                                        }

                                        > div {
                                            width: 24px;
                                            height: 24px;
                                            border-radius: 50%;
                                            border: 1px solid $color-grey-dark;

                                            @media (max-width: 992px) {
                                                width: 20px;
                                                height: 20px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .bold-blue {
                            font-family: $font-open-sans-semibold;
                            color: $color-blue;
                        }
                    }
                }

                .store-divider {
                    border-top: 2px solid rgba($color-blue, 0.5);
                }

                .select-container {
                    width: 100%;
                    display: inline-flex;
                    justify-content: flex-end;

                    > p {
                        font-family: $font-open-sans-bold;
                        font-size: 18px;
                    }

                    .form-group {
                        margin-top: 0.5rem;
                        width: 100%;
                        max-width: 320px;

                        @media (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }

            }
        }
    }

    &__checkout {
        width: 100%;
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
        margin: $top-bar-height auto 18px;

        @media (max-width: 600px) {
            padding: 0;
        }

        &__controls {

            @media (max-width: 600px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &__checkbox {
                color: $color-grey;
                font-size: 15px;

                > div {
                    margin-bottom: 10px;
                }

                > span {
                    margin-left: 20px;
                }

                &--mobile {
                    width: 90vw;
                    font-size: 14px;
                    font-family: $font-open-sans;

                    > div {
                        margin-bottom: 0;
                    }

                    > :nth-child(5) {
                        margin-left: 0px;
                    }
                }
            }

            &__button {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-top: 40px;
                margin-bottom: 60px;
                height: 45px;

                > button {
                    display: flex;
                    justify-content: center;
                    width: 300px;

                    @media (max-width: 600px) {
                        width: 90vw;
                    }
                }
            }
        }

        &__address-container {
            font-family: $font-open-sans;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 51px;

            @media(max-width: 600px) {
                margin: auto auto 20px auto;
                width: 90vw;
            }

            > hr {
                margin-top: 25px;
                margin-bottom: 25px;
            }

            > h4 {
                font-family: $font-open-sans-bold;
                color: $color-blue-dark;
                margin-bottom: 72px;

                @media(max-width: 600px) {
                    width: 90vw;
                    font-size: 18px;
                    margin-bottom: 25px;
                }
            }


            &__form-single-field {
                width: 600px;
                height: 40px;
                margin-bottom: 19px;

                @media(max-width: 600px) {
                    margin: auto auto 10px;
                    width: 90vw;
                }
            }

            &__form-multi-field {
                > div {
                    width: 293px;
                }

                display: flex;
                justify-content: space-between;
            }

            &__checkbox-container {
                width: 60%;
                margin-top: 1em;


                @media(max-width: 600px) {
                    width: 90vw;
                }

                > div {
                    > label {
                        font-family: $font-open-sans;
                        font-size: 15px;
                        color: $color-grey;
                        margin-left: .5em;
                    }
                }
            }

        }

        > button {
            display: block;
            width: 320px;
            height: 48px;
            margin-top: 40px;
            margin-right: auto;
            margin-bottom: 60px;
            margin-left: auto;
            font-size: 20px;

            @media(max-width: 600px) {
                font-family: $font-open-sans;
            }
        }

        &__step-header {
            width: 600px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            margin-top: 50px;
            margin-bottom: 70px;

            @media (max-width: 600px) {
                width: 100vw;
                justify-content: flex-start;
                margin-top: 0px;
                box-shadow: 0px 5px 5px $color-shadow;
                padding-bottom: 20px;
            }

            > span {
                width: 20%;
                height: 1em;
                border-top: 2px dashed $color-light-grey;
                margin-top: 32px;

                @media (max-width: 600px) {
                    width: 100%;
                }
            }

            > div {
                min-width: 160px;
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;

                @media (max-width: 600px) {
                    min-width: 100px;
                    max-width: 100px;
                }

                > div {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 14px;
                    border-radius: 50%;

                    @media (max-width: 600px) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        height: 45px;
                        width: 45px;
                    }

                    > div {
                        > svg {
                            width: 40px;
                            height: 40px;

                            @media (max-width: 600px) {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }

                > h2 {
                    font-family: $font-open-sans-semibold;
                    font-size: 20px;
                    letter-spacing: 2px;
                    margin-top: 32px;

                    @media (max-width: 600px) {
                        font-family: $font-open-sans-bold;
                        font-size: 9px;
                        letter-spacing: .5px;
                        text-align: center;
                        margin-top: 10px;
                    }
                }
            }

            &__icon {

                &--active {
                    cursor: pointer;

                    > div {
                        background-color: $color-blue;

                        > * {
                            filter: contrast(200%) saturate(0%) brightness(150%);
                        }
                    }

                    > h2 {
                        color: $color-blue-dark;
                    }
                }

                &--inactive {

                    > div {
                        background-color: $color-light-grey;

                        > * {
                            filter: contrast(0%) saturate(0%) brightness(150%);
                        }
                    }

                    > h2 {
                        color: $color-grey;
                    }
                }
            }
        }

        .success-screen {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 40px;

            &__success-icon {
                margin-bottom: 10px;

                > img {
                    width: 3em;
                    height: 3em;
                }
            }

            .message {
                margin-top: 60px;
                padding: 1em;
                box-shadow: 0 0 10px $color-shadow;
                display: flex;
                flex-direction: column;
                align-items: center;
                border-radius: 10px;
                max-width: 510px;

                &__order-id {
                    display: flex;
                    text-align: left;
                    align-self: flex-start;
                    padding-left: 1em;
                    padding-bottom: .5em;
                    width: 100%;
                    border-bottom: 2px solid $color-light-grey;

                    > p {
                        font-family: $font-open-sans-light;
                        font-size: 1rem;
                    }

                    > b {
                        font-family: $font-open-sans-semibold;
                    }
                }

                &__processing {
                    border-top: 2px solid $color-light-grey;
                    padding-top: 1em;
                    font-family: $font-open-sans;
                    font-size: 1em;
                    color: $color-grey;
                }

                .payment-info {
                    margin-top: 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .information-container {
                        display: inline-flex;
                        font-family: $font-open-sans;

                        .bold {
                            font-family: $font-open-sans-semibold;
                        }
                    }
                }
            }


            p {
                font-size: 24px;
                font-family: $font-open-sans-semibold;
                color: $color-blue-dark;
            }

            p.small {
                font-size: 16px;
            }

            &__button {
                margin-top: 64px;
                > button {
                    display: flex;
                    justify-content: center;
                    min-width: 25%;
                    margin-bottom: 50px;

                    @media (max-width: 900px) {
                        display: flex;
                        width: 300px;
                        height: 50px;
                        font-family: $font-open-sans;
                        font-size: 20px;
                    }
                }
            }
        }

        .info-container {
            padding: 4px 16px;
            width: 100%;
            margin-bottom: 2rem;

            &--payment-method-container {
                display: flex;
                justify-content: center;
                margin-bottom: 60px;

                > div {
                    display: flex;
                    flex-direction: column;
                    width: 1114px;

                    @media(max-width: 600px) {
                        padding: 10px;
                        width: 90vw;
                        border-radius: 10px;
                        box-shadow: 0 5px 5px $color-shadow;
                    }

                    > h2 {
                        font-family: $font-open-sans-semibold;
                        font-size: 25px;
                        color: $color-blue-dark;
                        margin-bottom: 40px;

                        @media(max-width: 600px) {
                            font-family: $font-open-sans-bold;
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                    }


                    > div {
                        > span {
                            font-family: $font-open-sans-light;
                            color: $color-grey;
                            font-size: 20px;
                            font-weight: bold;
                        }
                    }

                    .form-check-label {
                        width: 345px;
                        height: 120px;
                        border-radius: 5px;
                        box-shadow: 5px 5px 5px $color-shadow;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;


                        @media(max-width: 600px) {
                            width: 90%;
                            height: 60px;
                            box-shadow: none;
                            justify-content: flex-start;
                        }

                        > img {
                            max-height: 80px;
                        }

                        > p {
                            font-family: $font-open-sans-bold;
                            font-size: 20px;
                            color: $color-grey-dark;
                            padding-top: 8px;

                            @media(max-width: 600px) {
                                margin-left: 17px;
                                font-size: 16px;
                            }
                        }
                    }
                }
            }

            &--address-container {
                display: flex;
                align-content: center;
                font-family: $font-open-sans;
                color: $color-grey;
                margin-bottom: 90px;
                justify-content: center;

                @media(max-width: 600px) {
                    margin-bottom: 20px;
                }

                > div {
                    display: flex;
                    width: 1114px;

                    @media(max-width: 600px) {
                        width: 90vw;
                        flex-direction: column;
                    }
                }

                &__address {
                    width: 270px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    @media(max-width: 600px) {
                        width: 90vw;
                        padding: 20px;
                        margin-bottom: 10px;
                        box-shadow: 0px 5px 5px $color-shadow;
                    }

                    > h2 {
                        font-family: $font-open-sans-semibold;
                        font-size: 25px;
                        color: $color-blue-dark;
                        margin-bottom: 41px;


                        @media(max-width: 600px) {
                            font-size: 18px;
                            font-family: $font-open-sans-bold;
                            margin-bottom: 10px;
                        }
                    }

                    > span {
                        font-size: 20px;
                        width: 90%;
                        text-align: left;


                        @media(max-width: 600px) {
                            font-size: 15px;
                        }
                    }
                }
            }

            .content {
                width: 100%;

                .button-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;

                    .price-container {
                        font-size: 20px;
                        font-family: $font-open-sans;
                        margin-bottom: 1rem;
                        margin-top: 0.5rem;

                        &.small {
                            font-size: 16px;
                            margin-bottom: 0.25rem;
                            margin-top: 0;
                        }

                        .bold {
                            font-family: $font-open-sans-semibold;
                        }
                    }

                    .error {
                        padding: 5px;
                        font-family: $font-open-sans-semibold;
                        color: red;
                    }
                }
            }
        }

        .page-title {
            width: 100%;
            margin-bottom: 35px;
            text-align: center;
            display: flex;
            justify-content: center;

            @media(max-width: 600px) {
                width: 90%;
                margin-top: 0;
                text-align: left;
                justify-content: start;
            }

            &--step-header {
                width: 100%;
                margin-bottom: 0;
                text-align: center;
                display: flex;
                justify-content: center;
                margin-top: 79px;
            }

            &--shipping {
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                font-family: $font-open-sans-semibold;
                font-size: 25px;
                margin-bottom: 48px;

                @media(max-width: 600px) {
                    width: 65%;
                    font-family: $font-open-sans-bold;
                    letter-spacing: .5px;
                    line-height: 25px;
                    font-size: 18px;
                    color: $color-blue-dark;
                    text-align: left;
                }
            }
        }

        .cart-container {
            padding: 0;

            .warning-message {
                text-align: center;
                margin-bottom: 1rem !important;
            }

            .no-data {
                width: 100%;
                font-size: 22px;
                text-align: center;
                font-family: $font-open-sans-semibold;
            }

            .title {
                font-family: $font-open-sans-semibold;
                font-size: 18px;
                margin-bottom: 1em;
                color: $color-blue-dark;
            }

            .payment-options {
                display: flex;
                flex-direction: column;
                align-items: center;

                > p {
                    font-size: 25px;
                    letter-spacing: 0.75px;
                    margin-bottom: 55px;

                    @media (max-width: 600px) {
                        font-family: $font-open-sans-bold;
                        letter-spacing: 0.5px;
                        line-height: 25px;
                        font-size: 18px;
                        color: #23294e;
                        text-align: left;
                    }
                }

                &__fields-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;

                    > p {
                        font-size: 25px;
                        letter-spacing: 0.75px;
                        margin-bottom: 70px;

                        @media(max-width: 600px) {
                            margin-bottom: 20px;
                            font-size: 20px;
                            text-align: left;
                            width: 90vw;
                        }
                    }

                    &__form-single-field {
                        width: 600px;

                        @media(max-width: 600px) {
                            margin: auto auto 10px;
                            width: 90vw;
                        }
                    }

                    &__checkbox-container {
                        width: 60%;
                        margin-top: 1em;


                        @media(max-width: 600px) {
                            width: 90vw;
                        }

                        > div {
                            > label {
                                font-family: $font-open-sans;
                                font-size: 15px;
                                color: $color-grey;
                                margin-left: .5em;
                            }
                        }
                    }

                    > h6 {
                        font-size: 25px;
                        font-family: $font-open-sans-light;
                        color: $color-blue-dark;
                        margin: 2em;

                        @media(max-width: 600px) {
                            font-family: $font-open-sans;
                            font-size: 16px;
                            color: $color-grey;
                        }
                    }
                }

                &__options-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    width: 70%;
                    margin-bottom: 3em;

                    @media (max-width: 600px) {
                        flex-direction: column;
                        align-items: center;
                    }
                }

                .form-check {
                    display: inline-flex;
                    align-items: center;
                    width: 345px;
                    height: 120px;
                    margin-bottom: 10px;
                    padding: 15px;
                    box-shadow: 5px 5px 5px $color-shadow;
                    border-radius: 10px;

                    @media (max-width: 600px) {
                        width: 350px;
                        height: 70px;
                    }

                    input {
                        width: 15px;
                        height: 15px;
                        margin-left: -5px;
                    }

                    .form-check-label {
                        width: 345px;
                        height: 120px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;



                        > img {
                            max-height: 80px;

                            @media (max-width: 600px) {
                                max-height: 40px;
                            }
                        }

                        > p {
                            font-family: $font-open-sans-bold;
                            font-size: 20px;
                            color: $color-grey-dark;
                            padding-top: 8px;

                            @media (max-width: 600px) {
                                font-size: 18px;
                                margin-right: 10px;
                            }
                        }

                    }
                }

                .extra {
                    margin-bottom: 10px;
                    padding: 0 20px;

                    .bold {
                        font-family: $font-open-sans-semibold;
                    }

                    .form-group {
                        max-width: 200px;
                    }
                }
            }

            .store-cart-container {
                font-family: $font-open-sans;
                font-size: 16px;

                @media (max-width: 600px) {
                    max-width: 100vw;
                    padding: 0 10px;
                }

                &__delivery-price {
                    font-family: $font-open-sans-semibold;
                    color: $color-blue;
                    margin-top: -1em;
                }

                .store-name {
                    font-family: $font-open-sans-bold;
                    font-size: 20px;
                    color: $color-blue-dark;
                    text-transform: uppercase;
                    letter-spacing: 0.6px;
                    cursor: pointer;

                    @media (max-width: 600px) {
                        font-size: 16px;
                    }
                }

                .line-container {
                    width: 100%;
                    display: inline-flex;
                    margin-bottom: 2rem;

                    @media (max-width: 600px) {
                        display: flex;
                        flex-direction: column;
                    }

                    img {
                        width: 95px;
                        height: 95px;
                        cursor: pointer;
                        box-shadow: 10px 10px 5px $color-shadow;
                        margin-right: 20px;
                        margin-top: 5px;
                    }

                    .line-info {
                        padding: 0 1rem;
                        width: 600px;
                        font-size: 17px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @media (max-width: 600px) {
                            max-width: 90vw;
                            padding: 0;
                        }

                        > span {
                            margin-top: -8px;
                            margin-bottom: 18px;
                            letter-spacing: 0.75px;

                            @media (max-width: 600px) {
                                margin-bottom: 0;
                            }
                        }

                        .bonus {
                            color: $color-grey;
                        }

                        > div {
                            display: flex;
                            justify-content: space-between;

                            > p {
                                text-align: right;
                                width: 200px;
                                margin-right: 200px;
                                margin-bottom: 0;
                                align-self: flex-end;

                                @media (max-width: 600px) {
                                    margin-right: 0;
                                }
                            }

                            > div {
                                width: 180px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;

                                @media (max-width: 600px){
                                    margin-top: 5px;
                                }

                                > p {
                                    display: flex;
                                    font-family: $font-open-sans-light;
                                    font-size: 20px;
                                    color: $color-grey;
                                    align-items: center;
                                    margin-bottom: 0px;

                                    @media (max-width: 600px){
                                        margin-bottom: 5px;
                                        font-family: $font-open-sans-semibold;
                                        font-size: 18px;
                                        color: $color-blue-dark;
                                    }

                                    > div {
                                        width: 24px;
                                        height: 24px;
                                        border-radius: 50%;
                                        border: 1px solid $color-grey;
                                    }
                                }
                            }
                        }

                        .blue {
                            font-family: $font-open-sans;
                            color: $color-blue;
                        }

                        .controls-container {
                            padding-top: 0.5rem;
                            width: 100%;
                            max-width: 200px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: space-between;

                            .quantity-controls {
                                padding-top: 0;
                            }
                        }

                        .product-title {
                            font-family: $font-open-sans-light;
                            font-size: 25px;
                            cursor: pointer;

                            @media (max-width: 600px){
                                margin-top: 5px;
                                font-size: 18px;
                                font-family: $font-open-sans-bold;
                                color: $color-blue-dark;
                            }

                            &__quantity {
                                font-size: 15px;
                                color: $color-grey;

                                @media (max-width: 600px){
                                    font-size: 12px;
                                    font-family: $font-open-sans;
                                }
                            }
                        }

                        .bold-blue {
                            font-family: $font-open-sans-bold;
                            color: $color-blue;
                            font-size: 35px;

                            @media (max-width: 600px){
                                    font-family: $font-open-sans;
                                    font-size: 18px;
                                    color: $color-blue-dark;
                            }
                        }
                    }
                }

                .store-divider {
                    border-top: 2px solid rgba($color-blue, 0.5);
                }

                .radio-container {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 20px;

                    .form-radio {
                        display: flex;
                        flex-direction: column;

                        &__item {
                            margin-bottom: 10px;

                            > label {
                                display: flex;
                                font-family: $font-open-sans-semibold;
                                color: $color-grey;
                                cursor: pointer;
                                white-space: pre-line;

                                > input {
                                    display: none;
                                }

                                > div {
                                    display: flex;
                                    justify-content: center;
                                    align-content: center;
                                    width: 28px;
                                    height: 28px;
                                    padding: 3px;
                                    border: 2px solid $color-light-grey;
                                    border-radius: 50%;
                                    margin-right: 15px;

                                    > div {
                                        width: 18px;
                                        height: 18px;
                                        border-radius: 50%;
                                    }
                                }
                            }

                            &__fake {
                                &--checked {
                                    background-color: $color-blue;
                                }
                            }
                        }
                    }
                }
            }

            .select-container {
                width: 100%;
                display: inline-flex;
                justify-content: flex-end;

                > p {
                    font-family: $font-open-sans-bold;
                    font-size: 18px;
                }

                .form-group {
                    margin-top: 0.5rem;
                    width: 100%;
                    max-width: 320px;

                    @media (max-width: 600px) {
                        width: 100%;
                    }
                }
            }

        }
    }

    .raffle-input {
        flex: 1;
    }

    .raffle-line {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 10px;
    }

    .raffle-button {
        border-radius: 30px;
    }

    .raffle-form {
        display: flex;
        flex-direction: column;
        max-width: 500px;
    }

    .raffle-banner {

        margin-top:  -36px;
        @media (max-width: 1200px) {
            overflow: hidden;
            img{
                margin: 0 400px 0 0;
            }
        }
        @media (max-width: 600px) {
            img{
                height: 220px;
                margin: 0 270px 0 0;
            }
        }
    }

    &__raffle-screen {
        overflow-x: hidden;
    }
}
