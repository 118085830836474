/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
 */

.home-categories-button {
    > img {
        width: 40px;
        margin-top: 10px;
        cursor: pointer;
    }

    &--expanded {
        > img {
            width: 40px;
            margin-top: 10px;
            cursor: pointer;
            transform: scaleY(-1);
        }
    }
}

.home-categories-selector {
    display: grid;
    grid-template-columns: 357px 403px 357px;
    justify-items: center;
    width: 1120px;
    transition: height 1s;
    height: 682px;
    overflow-y: hidden;


    @media (max-width: 1100px) {
        overflow-x: hidden;
        grid-template-columns: 50% 50%;
        width: 100vw;
        height: 430px;
    }

    &.expanded {
        height: 1150px;

        @media (max-width: 1100px) {
            height: 760px;
        }
    }

    * {
        border-radius: 10px;

        @media (max-width: 1100px) {
            border-radius: 5px;
        }
    }

    > hr {
        width: 1000px;
        border: 2px solid $color-light-grey;
        margin-top: 10px;
        margin-bottom: 21px;
    }

    > :nth-child(1) {
        grid-column: 1;
        grid-row: 1 / span 2;
        height: $tall-card-height;

        @media (max-width: 1100px) {
            height: $tall-card-height-mobile;
        }
    }

    > :nth-child(4) {
        grid-column: 3;
        grid-row: 1 / span 3;
        height: $largest-card-height;

        @media (max-width: 1100px) {
            grid-column: 2;
            grid-row: 3 / span 2;
            height: $tall-card-height-mobile;
        }
    }

    > div {
        position: relative;
        cursor: pointer;
        width: 357px;
        background-position: top left;
        background-attachment: scroll;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 21px;

        &:hover {
            > div {
                opacity: 1;
            }
        }

        @media (max-width: 1100px) {
            width: 45vw;
            margin-bottom: 10px;
        }

        > div {
            display: grid;
            grid-template-rows: 95% 5%;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgba($color-blue, 0.71);
            opacity: 0;
            transition: opacity .15s;

            @media (max-width: 1100px) {
                position: absolute;
                bottom: 0;
                height: unset;
                opacity: 1;
                background-color: rgba($color-blue-dark, 0.71);
            }

            > p {
                margin: 0 10px 0 10px;
                justify-self: center;
                font-family: $font-open-sans-bold;
                font-size: 30px;
                letter-spacing: .9px;
                text-align: center;
                color: #ffffff;

                @media (max-width: 1100px) {
                    margin: 0;
                    font-size: 13px;
                    letter-spacing: .2px;
                }
            }

            > img {
                justify-self: flex-end;
                align-self: flex-end;
                position: relative;
                right: 30px;
                bottom: 30px;

                @media (max-width: 1100px) {
                    display: none;
                }
            }
        }
    }

    &__divider {
        grid-column: 1 / span 3;
        grid-row: 5;

        @media (max-width: 1100px) {
            grid-column: 1 / span 2;
        }
    }

    &__panel {
        height: $short-card-height;

        @media (max-width: 1100px) {
            height: $short-card-height-mobile;
        }
    }
}