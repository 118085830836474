.navbar {
    background-image: linear-gradient($color-blue-dark, transparent);
    background-color: transparent;
    height: 94px;
    transition: background-color .5s ease-in-out;

    &__search-container {
        width: 100%;
        max-width: 488px;
        min-width: 310px;
        height: 40px;
        display: inline-flex;
        justify-content: center;

        @media (max-width: 991px) {
            max-width: unset;
            min-width: unset;
        }

        .search {
            width: 90%;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 23px;
            background-color: #FFFFFF;

            @media (max-width: 991px) {
                box-shadow: 0 4px 3px $color-grey-dark;
                max-width: 600px;
            }

            .button {
                height: 36px;
                padding-left: 10px;
                padding-right: 10px;
                font-family: $font-open-sans-semibold;
            }

            .search-icon {
                display: flex;
                width: 36px;
                height: 32px;
                margin-right: 5px;
                background-color: $color-blue;
                border-radius: 50%;
                cursor: pointer;

                @media (max-width: 991px) {
                    background-color: unset;
                }

                > img {
                    width: 14px;
                    height: 14px;
                    margin: auto;

                    @media (max-width: 991px) {
                        width: 18px;
                        height: 18px;
                        margin-right: 29px;
                        filter: brightness(0) saturate(100%) invert(55%) sepia(96%) saturate(674%) hue-rotate(166deg) brightness(87%) contrast(101%);
                    }
                }
            }

            .search-field {
                display: inline-flex;
                align-items: center;
                width: 430px;
                height: 36px;
                margin-left: 10px;


                > div {
                    > input {
                        font-family: $font-open-sans-semibold;
                        font-size: 14px;
                        letter-spacing: .42px;
                        color: $color-grey-dark;
                    }
                }

                .vertical-divider {
                    height: 30px;
                    background-color: $color-blue;
                    border: 1px solid $color-blue;
                }

                select:focus {
                    outline: none;
                }

                select {
                    text-align: center;
                    color: $color-blue-dark;
                    border: none;
                    min-width: 160px;
                    padding-left: 2rem;
                    background-image: url('../../../images/icon-down_arrow_blue.svg');
                    background-position: right;
                    background-size: 15px 15px,
                    15px 15px,
                    1.5em 1.5em;
                    background-repeat: no-repeat;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                }
            }

            @media (max-width: 400px) {
                .button {
                    width: 85px;
                    min-width: 85px;
                }
            }
        }
    }

    &--mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        background-image: linear-gradient($color-blue-dark, transparent);
        padding: 0 10px;
        transition: background-color .5s ease-in-out;
        height: 100px;

        @media (max-width: 991px) {
            max-width: 100vw;
        }

        .container {
            display: flex;
            width: 100%;
            max-width: 1144px;
            height: 100%;
            padding: 0;
            font-family: $font-open-sans-bold;
            font-size: 14px;

            &--mobile {
                display: flex;
                flex-direction: column;

                &__top {
                    display: flex;
                    width: 100%;
                    margin-bottom: 5px;

                    > div {
                        align-items: center;
                    }

                    &__left {
                        width: 50%;
                        display: flex;
                        justify-content: flex-start;

                        .navbar-brand--mobile {
                            width: 150px;
                            margin: 0;
                        }
                    }

                    &__right {
                        display: flex;
                        justify-content: flex-end;
                        width: 50%;

                        > * {
                            margin-left: 25px;
                        }

                        .icon-wrapper {
                            position: relative;
                            cursor: pointer;
                            max-width: 56px;

                            .svg-icon span {
                                width: 40px;
                                height: 42px;

                                svg {
                                    width: 40px;
                                    height: 42px;
                                }
                            }

                            .count-container {
                                position: absolute;
                                top: -6px;
                                left: -6px;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                width: 15px;
                                height: 15px;
                                background-color: $color-blue;
                                color: #ffffff;
                                font-family: $font-open-sans;
                                font-size: 12px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }

    &--blue {
        background-color: $color-blue-dark;
    }

    .container {
        width: 100%;
        max-width: 1144px;
        height: 100%;
        padding: 0;
        font-family: $font-open-sans-bold;
        font-size: 14px;

        &__left {
            justify-content: flex-start;

            > div {
                cursor: pointer;
            }

            > a {
                height: 100%;

                img {
                    width: 243px;
                    height: 73px;
                    cursor: pointer;
                }
            }
        }

        &__right {
            justify-content: flex-end;

            > * {
                margin-left: 22px;
            }

            &__img {
                display: flex;
                width: 40px;
                height: 40px;
                background-color: $color-blue;
                border-radius: 50%;
                cursor: pointer;

                > img {
                    width: 20px;
                    height: 20px;
                    margin: auto;
                }
            }
        }

        > div {
            height: 100%;
            display: flex;
            align-items: center;

            > img {
                cursor: pointer;
            }
        }

        .navbar-brand {
            align-self: flex-start;
            margin: 0 0 0 32px;
            padding: 0;
        }

        &.small-device {
            .navbar-brand {
                img {
                    height: 40px;
                }
            }

            .navbar-toggler {
                height: 40px;
            }
        }

        @media (max-width: 991px) {
            > a {
                img {
                    height: 50px;
                }
            }
        }

        @media (min-width: 992px) {
            .top-links-container {
                display: flex !important;
                flex-basis: auto;
                justify-content: space-between;
                padding: 0 1rem;
            }
        }

        .top-links-container {
            flex-basis: 100%;
            flex-grow: 1;
            width: 100%;
            align-items: center;

            a.nav-link {
                padding-bottom: 0;
                padding-top: 0;
            }
        }

        .navbar-toggler {
            border: none;
        }

        .button {
            font-size: 14px;
            font-family: $font-open-sans-bold;
            padding: 0;

            &:hover {
                color: $color-blue;
            }
        }

        .underlined {
            padding-bottom: 2px;
            font-family: $font-open-sans-bold;
            border-bottom: 3px solid $color-blue;
            margin-left: .5rem;
            margin-right: .5rem;
            max-width: 105px;
            padding-left: 3px !important;
            padding-right: 3px !important;

            @media (max-width: 991px) {
                margin-left: 0;
            }
        }

        .avatar {
            .dropdown-menu {
                font-family: $font-open-sans-semibold;
                font-size: 15px;
                min-width: 12rem;
                color: $color-blue-dark;

                .dropdown-item-text {
                    padding: 0.5rem 1rem;
                    text-align: center;
                    font-family: $font-open-sans-bold;
                }

                .dropdown-item {
                    padding: 0.25rem 1rem;

                    &.active {
                        color: unset;
                        background-color: unset;
                    }
                }
            }
        }

        .clickable {
            cursor: pointer;
        }

        .nav-link {
            color: $color-blue-dark !important;

            &:hover {
                color: $color-blue !important;
            }
        }

        .user-container {
            text-align: right;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .nav-link {
                padding: 0;
            }

            @media (max-width: 991px) {
                text-align: left;

                .name {
                    margin-left: 10px !important;
                }
            }
        }

        .icon-wrapper {
            position: relative;
            cursor: pointer;
            max-width: 56px;

            .svg-icon span {
                width: 40px;
                height: 42px;

                svg {
                    width: 40px;
                    height: 42px;
                }
            }

            .count-container {
                position: absolute;
                top: -13px;
                left: -6px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                background-color: #ffffff;
                color: $color-blue;
                font-family: $font-open-sans;
                font-size: 14px;
                border-radius: 50%;
            }

            @media (max-width: 991px) {
                padding-top: 7px;
                margin-top: 10px;
            }
        }
    }
}