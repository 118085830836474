.app-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: $footer-height;
    padding-top: 12px;
    font-family: $font-open-sans-light;
    font-size: 14px;
    color: rgba(#000000, 0.8);
    background-color: rgba(#838d9a, 0.1);

    @media (max-width: 980px) {
        height: $footer-height-medium;
    }

    @media (max-width: 384px) {
        height: $footer-height-mobile;
    }

    .footer-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        > h4 {
            font-family: $font-open-sans-bold;
            font-size: 20px;
            color: $color-blue-dark;
        }

        @media (max-width: 1150px) {
            > h4 {
                font-size: 16px;
            }
        }
    }

    &__contact-button-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        max-width: 360px;
        margin-top: 30px;
        padding-bottom: 20px;
        gap: 10px;

        @media (max-width: 400px) {
            align-items: center;
        }

        > div {
            width: 48%;
            display: flex;
            flex-direction: column;
            align-items: center;

            > p {
                text-align: center;
                max-width: 90%;
                font-size: 14px;
            }

            > button {
                display: flex;
                justify-content: center;
                align-content: center;
                width: 100%;
                height: 48px;
                padding: 0;

                > span {
                    text-transform: capitalize;

                    > span {
                        > svg {
                            width: 19px !important;
                            height: 19px !important;
                        }
                    }
                }
            }
        }

        @media (max-width: 400px) {
            min-width: unset;
            flex-direction: column;
            margin-top: 20px;

            > button {
                min-width: 160px;
                height: 48px;
                font-family: $font-open-sans;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }

        @media (max-width: 380px) {

            :first-child {
                margin-bottom: 5px;
            }
        }
    }

    &__soc-media-container {
        display: flex;
        justify-content: space-around;
        width: 150px;
        margin-top: 30px;
        margin-bottom: 30px;

        .social {
            padding: 0 3px;

            > img {
                width: 48px;
                height: 48px;
            }
        }

        @media (max-width: 400px) {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

    &__footer-links {
        display: flex;
        justify-content: space-evenly;
        width: 530px;
        margin-bottom: 14px;
        font-family: $font-open-sans-bold;
        text-align: center;

        > a {
            &:last-child {
                color: $color-blue;
            }
        }

        &__link {
            width: 25%;
            font-size: 16px;
            color: $color-grey;
        }

        @media (max-width: 860px) {
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 7px;
            margin-bottom: 0;

            &__link {
                width: 100%;
                padding-bottom: 7px;
            }
        }
    }

    .terms-container {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        min-height: 43px;
        margin-top: 1em;
        padding: .5rem 5rem;
        background-color: #d1d5da;

        > p {
            font-size: 12px;
        }

        > div {
            display: flex;
            justify-content: space-evenly;
            column-gap: 38px;

            a {
                font-family: $font-open-sans;
                color: $color-blue-dark;

                span {
                    display: block;
                }
            }
        }

        @media (max-width: 980px) {
            display: flex;
            flex-direction: column-reverse;
            padding: 20px 0 0 0;
            text-align: center;
            height: 170px;
            background-color: #e6e8ec;

            > div {
                display: flex;
                flex-direction: column;
                width: 100%;

                a {
                    span {
                        display: inline-block;
                        margin-left: .5ch;
                    }
                }
            }

            > p {
                padding: 2px;
                margin: 0;
            }
        }
    }
}
