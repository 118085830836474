.form-select-field {
    margin-bottom: 15px;

    > select {
        appearance: none;
        font-family: $font-open-sans;
        background-image: url('../../../images/icon-down_arrow_blue.svg');
        background-position: 98% 50%;
        background-size: 20px 20px;
        background-repeat: no-repeat;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
    }
}