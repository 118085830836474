#root {
    height: 100%;
    width: 100%;
}

.app-screen, .app-router {
    height: 100%;
    width: 100%;

    .app-container {
        min-height: calc(100% - #{$footer-height});
        display: flex;
        flex-flow: column;

        .container-fluid {
            flex: 1 1 auto;
            height: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            max-width: 100%;
            background-color: #FFFFFF;
        }
    }

    .contact-icon-container {
        position: fixed;
        right: 0;
        bottom: 15px;
        height: 50px;
        padding: 0 15px;

        &__whatsapp {
            right: 60px;
        }

        .wrapper {
            height: 50px;
            width: 50px;
            background-color: $color-blue;
            border-radius: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid $color-blue-dark;

            img {
                width: 28px;
            }

            &:hover {
                background-color: $color-blue-dark;
                transition: background-color 0.7s ease;
            }

            &__whatsapp {
                background-color: $color-whatsapp-green;

                &:hover {
                    background-color: $color-whatsapp-green-dark;
                }
            }
        }
    }
}

