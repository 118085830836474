.app-tabs {
    font-family: $font-open-sans-semibold;
    font-size: 16px;
    color: $color-blue-dark !important;

    .nav-link.active {
        color: $color-blue-dark !important;
    }

    a {
        color: unset;
    }

    .nav-item:hover {
        color: $color-blue !important;
    }

    &.top-margin {
        margin-top: 20px;
    }

    &__tab-content {
        padding: 10px;
        font-family: $font-open-sans;

        &.customer-admin {
            &.store-manager {
                max-width: 100%;

                .orders-buttons {
                    > :first-child {
                        margin-right: 10px;
                    }

                    > button {
                        justify-content: center;
                        max-width: 100px;
                        height: 30px;
                    }

                    @media (max-width: 1710px) {
                        > :first-child {
                            margin-bottom: 5px;
                        }

                        > button {
                            width: 100px;
                        }
                    }
                }
            }

            table {
                .total-cell {
                    max-width: 220px;
                    width: 150px;
                }

                .date-cell {
                    max-width: 300px;
                    width: 200px;
                }
            }
        }

        &.store-admin,
        &.user-admin {

            h5 {
                font-family: $font-open-sans-bold;
                font-size: 25px;
            }

            .history-form {

                > h5 {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                &__content {
                    display: grid;
                    grid-template-columns: 50% 50%;

                    > div {
                        padding: 0;
                    }
                }
            }

            .upload-container-grid {
                display: grid;
                grid-template-columns: 50% 50%;

                &__upload-component {
                    display: flex;

                    .image-upload {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 240px;

                        > img {
                            margin: 0;
                        }
                    }

                    > div {
                        button {
                            margin-top: 5px;
                        }
                    }

                    > button {
                        display: flex;
                        justify-content: center;
                        width: 30px;
                        position: relative;
                        right: 60px;
                    }
                }
            }

            .upload-container {
                max-height: 350px;
                overflow-y: scroll;

                &__upload-component {
                    display: flex;

                    .image-upload {
                        width: 240px;
                    }

                    > button {
                        align-self: flex-end;
                        margin-bottom: 45px;
                    }
                }
            }

            .banner-upload {
                width: 100%;

                .image-upload {
                    display: flex;
                    flex-direction: column;

                    > img {
                        width: 100%;
                        height: auto;
                        max-height: 268px;
                        margin-bottom: 20px;
                    }
                }

                > button {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    left: 100px;
                    bottom: 88px;
                }
            }

            .form-centered {
                padding: 1rem 25rem;
                margin-bottom: 2rem;

                &__form-centered-large {
                    padding: 1rem 16rem;
                }

                @media (max-width: 1800px) {
                    padding: 1rem 12rem;
                }

                @media (max-width: 1600px) {
                    padding: 1rem 8rem;
                }

                @media (max-width: 1400px) {
                    padding: 1rem 5rem;
                }

                @media (max-width: 1200px) {
                    padding: 0.5rem 1.5rem;
                }

                @media (max-width: 1000px) {
                    padding: 0.25rem 0.825rem;
                }

                @media (max-width: 575px) {
                    padding: 0.25rem 0.25rem;
                }

                h5 {
                    font-size: 20px;
                    font-family: $font-open-sans-semibold;
                }

                .schedule-add-btn-wrapper {
                    margin-bottom: 1rem;
                }

                .schedule-row {
                    .schedule-remove-column {
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .button {
                            margin-top: 15px;
                            width: fit-content;
                            width: -moz-fit-content;
                        }
                    }
                }

                .info-bold {
                    font-family: $font-open-sans-semibold;
                }

                .fixed-area {
                    resize: none;
                }
            }
        }

        &.health-products-tab {
            .select-container {
                .form-group {
                    width: 100%;
                    max-width: 400px;
                }
            }
        }

        .select-container {
            > .div {
                width: 100%;
                max-width: 400px;

                .form-group {
                    width: 100%;
                }
            }
        }

        .clickable {
            cursor: pointer;

            > td {
                .banner-img {
                    max-width: 160px;
                }

                .chevron {
                    width: 30px;
                    cursor: pointer;

                    &--up {
                        transform: scaleY(-1);
                    }
                }
            }
        }

        .order-status-cell {
            min-width: 145px;
            max-width: 180px;

            > .form-group {
                margin-bottom: 0;
                font-size: 14px;

                select {
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 14px;
                    height: calc(1em + .75rem + 2px);
                }
            }
        }

        .buttons-container {
            width: 100%;
            display: flex;
            justify-content: space-between;

            @media (max-width: 955px) {
                flex-direction: column;
            }

            &__filters {
                display: flex;
                justify-content: space-between;

                > * {
                    width: 210px;
                    margin-right: 10px;
                }

                
                &--action-select {
                    background-image: url('../../../images/icon-down_arrow_white.svg');
                    color: white;
                    background-color: #23294e;
                    border-radius: 30px;
                    background-position: 95% 50%;
                }

                @media (max-width: 955px) {
                    flex-direction: column;
                    align-items: center;

                    > * {
                        margin-bottom: 5px;
                    }
                }
            }

            &__buttons {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                align-items: center;

                > * {
                    margin-left: 5px;
                }


                @media (max-width: 955px) {
                    justify-content: center;

                    > * {
                        margin-right: 0;
                    }
                }
            }

            .button-wrapper {
                grid-column: 1 / span 2;
                display: flex;
                justify-content: flex-end;
                padding: 5px 5px;

                @media (max-width: 955px) {
                    padding: 5px 0;
                }
            }
        }

        ul, table {
            margin-top: 15px;
            padding-left: 0;
        }

        .checkbox--products {
            height: 18px;
            width: 18px;
        }

        .trash-icon-cell {
            padding-top: 8px;
            
            td:first-of-type {
                border-top: none;
                background-color: #24a6df;
            }

            svg {
                width: 18px;
                height: 18px;
                filter: brightness(0) saturate(100%) invert(9%) sepia(68%) saturate(6613%) hue-rotate(358deg) brightness(111%) contrast(113%);
            }
        }

        .restore-icon-cell {
            padding-top: 10px;
            
            svg {
                width: 18px;
                height: 18px;
            }
        }

        .sort-fields {
            column-gap: 10px;
            margin-top: 5px;
        }
        .category-item {
            cursor: pointer;

            h5 {
                font-family: $font-open-sans-bold;
                font-size: 16px;
            }

            > p {
                font-family: $font-open-sans-light;
                font-size: 14px;
                padding-left: 10px;
            }

            .subcategories-list {
                font-size: 14px !important;
                padding-left: 15px;

                > h6 {
                    font-size: 14px !important;
                    font-family: $font-open-sans-semibold;
                }

                > p {
                    font-family: $font-open-sans-light;
                }
            }

            .svg-icon {
                svg {
                    width: 18px;
                    height: 18px;
                    fill: rgba($color-red, 0.8);
                }
            }
        }
    }

    &__arrow {
        margin-left: 5px;
        
        &--descending {
            margin-left: 5px;
            transform: scaleY(-1);
        }
        &--active {
            filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
        }
    }

}