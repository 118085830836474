.product-color-buttons {
    display: flex;
    align-items: center;

    >:first-child {
        margin-left: 0px;
    }

    &__item {
        margin-left: 10px;

        > label {
            display: flex;
            width: 28px;
            height: 28px;
            font-family: $font-open-sans-semibold;
            color: $color-grey;
            cursor: pointer;

            > input {
                width: 0;
                height: 0;
                opacity: 0;
            }

            > div {
                display: flex;
                justify-content: center;
                align-content: center;
                width: 30px;
                height: 30px;
                padding: 3px;
                border-radius: 50%;

                > div {
                    border-radius: 50%;
                    border: 1px solid $color-grey;
                }
            }
        }
        &__fake {
            > div {
                width: 25px;
                height: 25px;
            }

            &--checked {
                border: 2px solid $color-grey-dark;
                > div {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}