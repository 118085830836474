.contact-popover {
    padding: 0.5rem;

    &.popover {
        max-width: 400px;
        width: 315px;
        z-index: 1150;
    }

    .arrow {
        display: none !important;
    }

    .popover-body {
        .terms-checkbox-container {
            font-family: $font-open-sans;
            margin-bottom: 0.5rem !important;

            > div {
                display: inline-flex;

                input {
                    margin-top: 5px;
                    margin-right: 4px;
                }

                a {
                    cursor: pointer;
                    font-family: $font-open-sans-semibold;
                    text-decoration: underline;
                    color: unset;
                    font-size: 14px;
                }

                label {
                    margin-bottom: 0.25rem;
                    font-size: 14px;
                }
            }

            .error-field {
                padding-left: 1rem;
                line-height: 1rem;
                color: red;
                font-size: 13px;
                margin-bottom: 0.5rem !important;
                font-family: $font-open-sans-semibold;
            }
        }

        .recaptcha-error {
            font-family: $font-open-sans-semibold;
            color: red;
            font-size: 13px;
        }
    }

}