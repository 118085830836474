/**
 *
 * @Copyright 2020 VOID SOFTWARE, S.A.
 *
*/

.join-us-screen {
    width: 100%;

    @media (max-width: 600px) {
        .carousel-control-prev, .carousel-control-next {
            display: none;
        }
    }

    &__carousel-container {
        position: relative;
        width: 100%;

        @media (max-width: 900px) {
            height: 400px;
        }

        &__header {
            background: url('../../../images/header_leiriamarket_comerciantes.png');
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-size: cover;
            height: 60vh;

            @media (max-width: 900px) {
                background-size: 1200px 400px;
                background-position-x: 72%;
            }
            @media (min-width: 900px) {
                background-position: top;
            }


            .pretitle {
                font-family: $font-open-sans-bold;
                color: #FFFFFF;
                font-size: 1rem;
                text-transform: uppercase;
                margin-bottom: 1rem !important;

                @media(max-width: 900px) {
                    font-size: 12px;
                    margin-top: $top-bar-height;
                }
            }

            &__title-container {
                max-height: 100px;
            }

            .title {
                font-family: $font-open-sans-bold;
                color: $color-blue-dark;
                max-width: 800px;
                height: min-content !important;

                @media(max-width: 900px) {
                    font-size: 20px;
                }
            }

            .subtitle {
                font-family: $font-open-sans;
                color: $color-blue-dark;
                font-size: 1rem;
                letter-spacing: 2px;

                @media(max-width: 900px) {
                    font-family: $font-open-sans;
                    font-size: 20px;
                }
            }

            .text-container {
                width: 80%;
            }

            @media (max-width: 700px) {
                width: 100%;
            }

            @media (max-width: 400px) {
                text-align: center;
            }

            &.centered {
                display: inline-flex;
                width: 100%;
                justify-content: center;

                .container {
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;

                    @media (max-width: 900px) {
                        justify-content: flex-start;
                    }


                    &__center {
                        height: 80%;
                        @media (max-width: 900px) {
                            height: 40%;
                            width: 100%;
                            text-align: left;
                            padding-top: 4em;

                            > div {

                                > h1 {
                                    font-size: 2rem;

                                    @media(max-width: 900px) {
                                        font-size: 25px;
                                    }
                                }
                                > * {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__questions {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @media (max-width: 900px) {
            > h2 {
                font-size: 1.2rem;
            }

            > p {
                width: 80%;
            }
        }
        @media (min-width: 901px) {
            > h2 {
                font-size: 1.5rem;
            }

            > p {
                width: 50%;
            }
        }


        > h2 {
            font-family: $font-open-sans-semibold;
            color: $color-blue-dark;
            padding-top: 3rem;
            padding-bottom: 1.5rem;
        }

        > p {
            font-family: $font-open-sans;
            color: $color-grey;
            font-size: 0.8rem;
            padding-bottom: .8rem;

        }

        .bold {
            font-family: $font-open-sans-bold;
            padding-top: 0;
            padding-bottom: 3rem;
        }
    }

    &__features {
        &--desktop {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            margin: 0 auto;
            width: 1200px;

            @media(max-width: 1200px) {
                width: 100vw;
            }

            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 190px;
                text-align: center;
                margin: 32px;

                > h2 {
                    width: 200px;
                    font-family: $font-open-sans-bold;
                    padding-top: 24px;
                    color: $color-blue-dark;
                    font-size: 20px;
                    letter-spacing: .6px;
                }

                > h3 {
                    width: 300px;
                    font-family: $font-open-sans-semibold;
                    color: $color-grey;
                    font-size: 16px;
                    padding-top: 11px;
                }

                &__icon-container {
                    width: 120px;
                    height: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20%;
                    box-shadow: 5px 5px 15px $color-shadow;

                    > img {
                        width: 67px;
                        height: 67px;
                    }
                }
            }
        }

        &--mobile {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;

            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 90%;
                text-align: center;
                margin-left: 1em;
                margin-bottom: 2em;

                &__icon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 20%;
                    box-shadow: 5px 5px 15px $color-shadow;
                    padding: 1em;
                    min-height: 90px;
                }

                &__text-container {
                    text-align: left;
                    margin-left: 1em;
                    > h2 {
                        font-family: $font-open-sans-bold;
                        padding-top: .5em;
                        color: $color-blue-dark;
                        font-size: 1rem;
                    }

                    > h3 {
                        font-family: $font-open-sans-semibold;
                        color: $color-grey;
                        font-size: .8rem;
                        padding-top: .5em;
                    }
                }
            }
        }
    }

    &__taglines {

        &--mobile {
            display: flex;
            flex-direction: column;

            > div {
                background-position: top;
                background-repeat: no-repeat;
                background-attachment: scroll;
                background-size: cover;
                height: 30vh;
                margin-bottom: 2em;

                > div {
                    display: block;
                    margin-right: auto;
                    margin-left: auto;
                    margin-top: 3em;
                    text-align: center;
                    color: #FFFFFF;

                    > h2 {
                        font-size: 2em;
                        font-family: $font-open-sans;
                    }

                    > p {
                        font-size: 2em;
                        font-family: $font-open-sans-bold;
                    }
                }

            }

            &__container {
                &--left {
                    background: url('../../../images/img-comerciolocal.png');
                }

                &--right {
                    background: url('../../../images/img-compras-seguras.png');
                }
            }
        }

        &--desktop {
            display: flex;
            flex-direction: column;
            height: max-content;

            &__container {
                display: flex;
                width: 90vw;
                height: 50vh;

                > div {
                    display: flex;
                    width: 100%;
                    font-size: 3em;

                    > div {
                        top: 30%;
                        position: relative;
                        color: #FFFFFF;

                        > h2 {
                            font-size: .9em;
                            font-family: $font-open-sans;
                        }

                        > p {
                            font-size: .9em;
                            font-family: $font-open-sans-bold;
                        }
                    }

                    > img {
                        position: absolute;
                        height: 40vh;
                    }
                }

                &__image {
                    &--left {
                        > div {
                            left: 20%;
                            text-align: left;
                        }

                        > img {
                            left: 0;
                        }
                    }

                    &--right {
                        > div {
                            left: 60%;
                            text-align: right;
                        }

                        > img {
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;

        .form-group {
            margin-bottom: 10px;
        }

        .form-select-field {
            margin-bottom: 10px;
        }

        > h2 {
            font-family: $font-open-sans-semibold;
            color: $color-blue-dark;
            font-size: 1.4rem;
        }

        &__recaptcha {
            align-self: center;

            > p {
                color: $color-red;
            }
        }

        &--double-line {
            display: flex;
            justify-content: space-between;

            > * {
                width: 49%;
            }
        }

        form {
            margin-top: 3em;
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;

            @media (min-width: 650px) {
                width: 40%;
            }

            @media (max-width: 650px) {
                width: 80%;
            }

            > button {
                align-self: center;
                width: 20%;
                min-width: 150px;
                font-size: 1.2em;
                margin-top: 1em;
                display: flex;
                justify-content: center;
            }
        }
    }
}