.cookies-footer {
    position: fixed;
    padding: 0.25rem 0.5rem;
    left: 0;
    bottom: 0;
    background-color: rgba($color-blue, 0.94);
    z-index: 1000;
    width: 100%;
    display: inline;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;

    .button {
        margin-left: 10px;
        margin-bottom: 5px;
        height: 22px;
    }


    .clickable {
        cursor: pointer;
        font-family: $font-open-sans-semibold;
        white-space: nowrap;
        text-decoration: underline;

        &:hover {
            color: $color-blue-dark;
        }
    }

    .svg-icon {
        margin-left: 10px;
        width: 17px;
        height: 17px;

        span {
            display: inline-flex;
            align-items: center;
        }

        svg {
            width: 17px;
            height: 17px;
            fill: #FFFFFF;
        }
    }
}