.stores-list-header {
    margin-top: $top-bar-height;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 200px;
        background-position: top;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
    }

    &__content {

        &__text {
            margin-top: 43px;
            max-width: 1144px;
            width: 100%;
            text-align: center;
            align-self: center;

            @media (max-width: 992px) {
                margin-top: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            > h3 {
                width: 100%;
                font-family: $font-open-sans-bold;
                font-size: 25px;
                letter-spacing: 1.2px;
                color: $color-blue-dark;

                @media (max-width: 992px) {
                    font-size: 20px;
                    letter-spacing: unset;
                }
            }

            > div {
                margin-top: 15px;
                font-family: $font-open-sans;
                font-size: 17px;
                letter-spacing: .57px;
                color: $color-grey;
            }
        }

        &__links {
            width: 100%;
            max-width: 1144px;
            align-self: center;

            &--current {
                color: $color-blue;
            }

            > div {
                display: flex;
                font-family: $font-open-sans;
                font-size: 17px;
                letter-spacing: .57px;
                color: $color-grey;

                > a {
                    color: $color-grey;
                }
            }

        }
    }
}

.stores-list-screen {
    display: grid;
    max-width: 1144px;
    margin: 64px auto 40px auto;

    @media (max-width: 1023px) {
        margin-top: 0;
    }

    &__order-by {
        grid-row: 1;
        grid-column: 1 / span 3;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        @media (max-width: 1023px) {
            grid-column: 1 / span 2;
            width: 100vw;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0px;
        }

        &__results {
            display: flex;
            font-family: $font-open-sans-light;
            font-size: 20px;
            letter-spacing: .6px;
            color: $color-grey;
            margin-bottom: 20px;

            @media (max-width: 1023px) {
                white-space: nowrap;
                font-size: 15px;
            }

            .blue {
                color: $color-blue;
            }
        }

        &__controls {
            display: flex;
            align-items: center;
            font-family: $font-open-sans-bold;
            color: $color-blue-dark;

            @media (max-width: 1023px) {
                width: 90%;
                display: grid;
                grid-template-columns: 50% 50%;
                align-self: center;
                margin-left: 0;

                >:nth-child(1) {
                    grid-row: 1;
                    grid-column: 1 / span 2;
                    justify-self: center;
                    text-align: center;
                    margin-bottom: 15px;
                }

                > * {
                    margin-bottom: 15px;
                }

            }

            .sort-filter-button {
                > div {
                    border: none;
                    width: 198px;
                    height: 56px;
                    font-family: $font-open-sans-semibold;
                    font-size: 20px;

                    @media (max-width: 1023px) {
                        padding: 0;
                        display: flex;
                        align-content: center;
                        width: 120px;
                        height: 36px;
                        font-size: 15px;
                    }

                    > div {
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }

                &__active {
                    background-color: $color-blue;
                    color: #ffffff;
                }

                &__inactive {
                    background-color: rgba($color-blue, 0.1);
                    color: $color-blue;
                }
            }

            > h6 {
                margin: 0;
                margin-right: 15px;
                font-size: 14px;

                @media (max-width: 1023px) {
                    margin-right: 0;
                }
            }

            > div {
                margin-right: 7px;
            }
        }
    }


    &__filters-container {
        grid-column: 1;
        grid-row: 2;

        @media (max-width: 1023px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 15px;
            height: 80vh;
            overflow-y: scroll;
        }

        &__modal-header {
            height: 45px;
            box-shadow: 0 7px 5px $color-shadow;
            display: flex;

            > img {
                height: 20px;
                margin-bottom: 8px;
                cursor: pointer;
            }
        }

        &__search-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 38px;
            margin-bottom: 20px;
            padding: 0 10px 0 10px;
            border-radius: 30px;
            box-shadow: 0 3px 6px $color-shadow;

            > input {
                font-family: $font-open-sans-semibold;
                font-size: 14px;
                letter-spacing: .42px;
                color: $color-grey-dark;
            }

            > img {
                width: 14px;
                height: 14px;
                filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
            }
        }

        &__filter-title {
            font-family: $font-open-sans-bold;
            font-size: 14px;
            letter-spacing: .42px;
            color: $color-blue-dark;
        }


        &__clear-button {
            margin-top: 30px;

            > button {

                width: 270px;
                color: $color-blue;
                background-color: $color-light-grey;
            }
        }

        &__range {
            width: 240px;
            margin-top: 10px;

            > h6 {
                margin-bottom: 35px;
                font-family: $font-open-sans-semibold;
                font-size: 18px;
                color: $color-grey;
            }

            .rc-slider {
                margin-left: 15px;

                > * {
                    height: 8px;
                }

                .rc-slider-handle {
                    width: 23px;
                    height: 23px;
                    background-color: $color-blue;
                    border: none;
                }

                .rc-slider-track {
                    background-color: $color-blue;
                }
            }
        }

        &__filter {

            &--active {
                margin-bottom: 15px;
                padding: 5px 0 5px 10px;
                font-family: $font-open-sans;
                font-size: 16px;
                letter-spacing: .48px;
                color: $color-blue;
                background-color: rgba($color-blue, .2);
                border-radius: 20px;
                cursor: pointer;
            }

            &--inactive {
                margin-bottom: 15px;
                padding-left: 10px;
                font-family: $font-open-sans;
                font-size: 16px;
                letter-spacing: .48px;
                color: $color-grey;
                cursor: pointer;
            }
        }

        .form-radio {
            margin-left: 10px;
            font-family: $font-open-sans;
            font-size: 16px;
            color: $color-grey;

            > div {

                > label {

                    > input {
                        margin-right: 5px;
                    }
                }
            }
        }

        .filter-field {
            width: 280px;
            border-bottom: 1px solid $color-light-grey;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 55px;
                cursor: pointer;

                > img {
                    display: block;
                    margin-top: auto;
                    margin-bottom: auto;
                    padding-left: 16px;
                }

                > p {
                    width: 380px;
                    margin-bottom: 0;
                    font-family: $font-open-sans-semibold;
                    font-size: 18px;
                    text-align: left;
                }

                &__title {
                    color: $color-grey;

                    &--expanded {
                        color: $color-blue;
                    }
                }

                > div {
                    margin-right: 11px;
                    justify-self: flex-end;
                }

                &__button {
                    &--expanded {
                        filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
                    }
                }
            }

            > div {
                font-family: $font-open-sans-light;
                font-size: .95rem;
                color: $color-blue-dark;
                transition: height .5s;
            }

            &__content {
                height: 0;
                overflow: hidden;

                &--expanded {
                    height: 280px;
                    padding: 5px;
                    overflow-y: scroll;
                }
            }
        }

    }

    &__stores-results-container {
        display: flex;
        grid-column: 2;
        grid-row: 2;
        flex-direction: column;
        width: 832px;
        margin-left: 30px;

        @media (max-width: 1023px) {
            display: grid;
            grid-template-columns: 50% 50%;
            width: 95vw;
            margin: 0;
            align-items: center;
        }

        @media (max-width: 820px) {
            display: flex;
            flex-direction: column;
            width: 100vw;
        }

        &__store-container {
            display: flex;
            justify-content: space-between;
            width: 813px;
            height: 384px;
            margin-bottom: 33px;
            border-radius: 10px;
            box-shadow: 3px 3px 10px $color-shadow;

            @media (max-width: 1023px) {
                width: 90vw;
                max-width: 380px;
                height: 420px;
                flex-direction: column;
                justify-content: flex-start;
            }

            > img {
                width: 361px;
                min-width: 361px;
                height: 384px;
                border-radius: 10px;
                object-fit: cover;

                @media (max-width: 1023px) {
                    width: 100%;
                    min-width: unset;
                    min-height: 170px;
                }

            }

            &__content {
                display: grid;
                padding: 21px 26px;

                &__titles {
                    align-self: flex-start;

                    > h6 {
                        font-family: $font-open-sans-bold;
                        font-size: 10px;
                        letter-spacing: 1px;
                        color: $color-grey;
                        text-transform: uppercase;

                        @media (max-width: 1023px) {
                            font-family: $font-open-sans-semibold;
                            letter-spacing: .48px;
                            font-size: 10px;
                            color: $color-blue;
                        }
                    }

                    > h5 {
                        font-family: $font-open-sans-bold;
                        font-size: 20px;
                        letter-spacing: .6px;
                        color: $color-blue-dark;
                    }
                }

                &__info-container {
                    align-self: flex-start;
                    width: 442px;
                    height: 230px;
                    margin-top: 15px;

                    @media (max-width: 1023px) {
                        width: inherit;
                        height: inherit;
                        margin-top: 0;
                    }

                    &__row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 17px;
                        font-family: $font-open-sans;
                        font-size: 15px;
                        color: $color-blue-dark;

                        @media (max-width: 1023px) {
                            margin-bottom: 3px;
                            font-size: 13px;
                        }

                        > p {
                            max-width: 360px;
                            margin-top: auto;
                            margin-bottom: auto;
                        }

                        .open {
                            color: #00cc07;
                        }

                        .closed {
                            color: $color-grey;
                        }

                        .link {
                            color: $color-blue;
                            text-decoration: underline;
                            cursor: pointer;
                        }

                        > img {
                            width: 22px;
                            height: 22px;
                            margin-right: 26px;
                            filter: brightness(0) saturate(100%) invert(16%) sepia(29%) saturate(1067%) hue-rotate(192deg) brightness(95%) contrast(97%);

                            @media (max-width: 1023px) {
                                margin-right: 10px;
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }

                    > ul {
                        padding-left: 21px;

                        > li {
                            font-family: $font-open-sans-semibold;
                            font-size: 15px;
                            color: $color-grey;
                        }
                    }
                }

                &__bottom-buttons {
                    display: flex;
                    align-self: flex-end;
                    justify-content: flex-end;
                    align-content: flex-end;
                    width: 400px;

                    > button {
                        display: flex;
                        justify-content: center;
                        min-width: 111px;
                        text-transform: unset;
                        margin-left: 20px;

                        @media (max-width: 1023px) {
                            width: 140px;
                            height: 22px;
                            margin-top: 5px;
                            margin-left: 0;
                            padding: 0;
                            font-family: $font-open-sans;
                            letter-spacing: .6px;
                            font-size: 11px;
                            display: flex;
                            justify-content: center;

                            > img {
                                width: 9px;
                            }
                        }
                    }

                    @media (max-width: 1023px) {
                        display: grid;
                        grid-template-columns: 50% 50%;
                        justify-content: center;
                        width: inherit;

                        > :last-child {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }

    }

}