.cart-popover {
    &.added-to-cart {
        display: flex;
        flex-direction: column;
        padding: .8em;

        > div {
            display: flex;
            flex-direction: row;
            align-items: center;

            > div {
                margin-right: 1em;
            }

            > h3 {
                font-family: $font-open-sans-light;
                color: $color-blue-dark;
                font-size: 1.3rem;
            }
        }

        > button {
            margin-top: 1em;
            align-self: center;
        }
    }

    &.popover {
        max-width: 400px;

        &.bs-popover-bottom {
            margin-top: 0;
        }
    }

    .dropdown-item:focus {
        outline: none;
    }

    .arrow {
        display: none !important;
    }

    .popover-body {
        padding: 0.825rem;

        h5 {
            font-family: $font-open-sans-semibold;
            font-size: 16px;
        }

        .info-container {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
            font-family: $font-open-sans;
            font-size: 15px;
            padding: 0.825rem 0;

            > div {
                display: inline-flex;
            }

            .bold {
                font-family: $font-open-sans-semibold;
            }
        }

        .store-cart-container {
            font-size: 15px;

            .divider:first-of-type {
                margin-top: 0;
            }

            .bold {
                font-family: $font-open-sans-semibold;
            }

            .shopping-line-container {
                display: inline-flex;
                padding: 0.25rem 0.5rem;
                width: 100%;

                img {
                    width: 58px;
                    height: 58px;
                    vertical-align: unset;
                }

                .row {
                    align-items: center;
                }

                .line-info {
                    padding-left: 0.5rem;
                    width: 100%;

                    .controls-container {
                        padding-top: 0.5rem;
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;

                        .quantity-controls {
                            padding-top: 0;
                        }
                    }

                    .product-title {
                        font-family: $font-open-sans-semibold;
                        font-size: 14px;
                    }

                    .blue {
                        font-family: $font-open-sans;
                        font-size: 14px;
                        color: $color-blue;
                    }

                    .bold-blue {
                        font-family: $font-open-sans-semibold;
                        font-size: 14px;
                        color: $color-blue;
                    }
                }
            }

        }

        .buttons-wrapper {
            display: inline-flex;
            width: 100%;

            .button {
                width: 100%;
                justify-content: center;
            }

            :first-child {
                margin-right: 3px;
            }

            :last-child {
                margin-left: 3px;
            }
        }
    }
}