.products-list-header {
    &--spaced {
        margin-top: $top-bar-height;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        height: 344px;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        background-attachment: scroll;

        @media (max-width: 1023px) {
            height: 260px;
        }
    }

    &--campaign {
        > div {
            background-position: right;
        }

        &--mobile {
            > div {
                object-fit: cover;
                background-position: center;
            }
        }
    }

    &__content {

        &__links {
            width: 100%;
            max-width: 1144px;
            align-self: center;

            @media (max-width: 1023px) {
                margin-top: 30px;
                width: 95vw;
                margin-left: 20px;
            }

            > h3 {
                margin-top: 23px;
                font-family: $font-open-sans-bold;
                font-size: 40px;
                letter-spacing: 1.2px;
                color: $color-blue-dark;

                @media (max-width: 1023px) {
                    margin-top: 10px;
                    font-size: 25px;
                    color: #ffffff;
                }
            }

            > div {
                display: flex;
                font-family: $font-open-sans;
                font-size: 17px;
                letter-spacing: .57px;
                color: #ffffff;
                text-shadow: 1px 1px $color-grey-dark;

                > a {
                    color: #ffffff;
                }

                @media (max-width: 1023px) {
                    font-size: 12px;
                }
            }

        }
    }
}

.products-list-screen {
    display: grid;
    width: 100%;
    max-width: 1144px;
    margin: 64px auto 40px auto;
    grid-template-columns: 25% 90%;

    @media (max-width: 1023px) {
        grid-template-columns: unset;
    }

    &__order-by {
        grid-row: 1;
        grid-column: 1 / span 3;
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        width: 100%;
        max-width: 1144px;

        @media (max-width: 1023px) {
            grid-column: 1 / span 2;
            width: 100vw;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
        }

        &__results {
            display: flex;
            font-family: $font-open-sans-light;
            font-size: 20px;
            letter-spacing: .6px;
            color: $color-grey;

            @media (max-width: 1023px) {
                font-size: 15px;
            }

            .blue {
                color: $color-blue;
            }
        }

        &__controls {
            display: flex;
            align-self: flex-end;
            align-items: center;
            margin-left: 106px;
            font-family: $font-open-sans-bold;
            color: $color-blue-dark;

            @media (max-width: 1023px) {
                width: 90%;
                display: grid;
                grid-template-columns: 50% 50%;
                align-self: center;
                margin-left: 0;
                margin-top: 25px;

                >:nth-child(1) {
                    grid-row: 1;
                    grid-column: 1 / span 2;
                    text-align: center;
                    margin-bottom: 15px;
                }

                > * {
                    margin-bottom: 15px;
                }

            }

            > h6 {
                margin: 0;
                margin-right: 15px;
                font-size: 14px;

                @media (max-width: 1023px) {
                    margin-right: 0;
                }
            }

            > div {
                margin-right: 7px;
            }
        }
    }


    &__filters-container {
        grid-column: 1;
        grid-row: 2;

        @media (max-width: 1023px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 15px;
            height: 90vh;
            overflow-y: scroll;
        }

        &__search-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 38px;
            margin-bottom: 20px;
            padding: 0 10px 0 10px;
            border-radius: 30px;
            box-shadow: 0 3px 6px $color-shadow;

            > input {
                font-family: $font-open-sans-semibold;
                font-size: 14px;
                letter-spacing: .42px;
                color: $color-grey-dark;
            }

            > img {
                width: 14px;
                height: 14px;
                filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
            }
        }

        &__modal-header {
            height: 45px;
            box-shadow: 0 7px 5px $color-shadow;
            display: flex;

            > img {
                height: 20px;
                margin-bottom: 8px;
                cursor: pointer;
            }
        }

        &__filter-title {
            font-family: $font-open-sans-bold;
            font-size: 14px;
            letter-spacing: .42px;
            color: $color-blue-dark;
        }


        &__modal-buttons {
            margin-top: 30px;
            display: flex;

            > button {
                font-family: $font-open-sans;
                letter-spacing: .48px;
            }

            >:nth-child(1) {
                width: 150px;
                color: $color-blue;
                border: 2px solid $color-blue;
                background-color: #ffffff;
                margin-right: 10px;
            }

            >:nth-child(2) {
                width: 150px;
                color: #ffffff;
                background-color: $color-blue-dark;
            }
        }

        &__clear-button {
            margin-top: 30px;

            > button {
                width: 270px;
                color: $color-blue;
                background-color: $color-light-grey;
            }
        }

        &__range {
            width: 240px;
            margin-top: 10px;

            @media (max-width: 1023px) {
                width: 280px;
            }

            > h6 {
                margin-bottom: 35px;
                font-family: $font-open-sans-semibold;
                font-size: 18px;
                color: $color-grey;
            }

            .rc-slider {
                margin-left: 15px;

                > * {
                    height: 8px;
                }

                .rc-slider-handle {
                    width: 23px;
                    height: 23px;
                    background-color: $color-blue;
                    border: none;
                }

                .rc-slider-track {
                    background-color: $color-blue;
                }
            }
        }

        &__filter {

            &--active {
                margin-bottom: 15px;
                padding: 5px 0 5px 10px;
                font-family: $font-open-sans;
                font-size: 16px;
                letter-spacing: .48px;
                color: $color-blue;
                background-color: rgba($color-blue, .2);
                border-radius: 20px;
                cursor: pointer;
            }

            &--inactive {
                margin-bottom: 15px;
                padding-left: 10px;
                font-family: $font-open-sans;
                font-size: 16px;
                letter-spacing: .48px;
                color: $color-grey;
                cursor: pointer;
            }
        }

        .form-radio {
            margin-left: 10px;
            font-family: $font-open-sans;
            font-size: 16px;
            color: $color-grey;

            > div {

                > label {

                    > input {
                        margin-right: 5px;
                    }
                }
            }
        }

        .filter-field {
            width: 280px;
            border-bottom: 1px solid $color-light-grey;

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 55px;
                cursor: pointer;

                > img {
                    display: block;
                    margin-top: auto;
                    margin-bottom: auto;
                    padding-left: 16px;
                }

                > p {
                    width: 380px;
                    margin-bottom: 0;
                    font-family: $font-open-sans-semibold;
                    font-size: 18px;
                    text-align: left;
                }

                &__title {
                    color: $color-grey;

                    &--expanded {
                        color: $color-blue;
                    }
                }

                > div {
                    margin-right: 11px;
                    justify-self: flex-end;
                }

                &__button {
                    &--expanded {
                        filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
                    }
                }
            }

            > div {
                font-family: $font-open-sans-light;
                font-size: .95rem;
                color: $color-blue-dark;
                transition: max-height .5s;
            }

            &__content {
                max-height: 0;
                overflow: hidden;

                &--expanded {
                    height: auto;
                    padding: 5px;
                    overflow-y: auto;
                    max-height: 280px;
                }
            }
        }

    }

    &__products-results-container {
        grid-column: 2;
        grid-row: 2;
        max-width: 832px;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        margin-left: 40px;

        @media (max-width: 1023px) {
            width: 100vw;
            grid-template-columns: 50% 50%;
            margin: 0;
            justify-items: center;
        }

        &__product-container {
            width: 267px;
            height: 394px;
            display: flex;
            flex-direction: column;
            box-shadow: 3px 3px 10px $color-shadow;
            border-radius: 10px;
            padding: 10px 10px 15px 10px;
            cursor: pointer;
            margin-bottom: 40px;

            @media (max-width: 600px) {
                justify-content: flex-start;
                width: 180px;
                height: 270px;
            }

            > div {
                &:hover {
                    > div {
                        opacity: 1;
                    }
                }
            }

            &__caption-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 16px 0 0 5px;

                > h6 {
                    font-family: $font-open-sans-bold;
                    font-size: 12px;
                    letter-spacing: 1.2px;
                    color: $color-blue-dark;
                    text-transform: uppercase;

                    @media (max-width: 600px) {
                        font-size: 9px;
                    }
                }

                > h5 {
                    height: 25px;
                    font-family: $font-open-sans-light;
                    font-size: 20px;
                    letter-spacing: .6px;
                    color: $color-blue-dark;
                    margin-bottom: 14px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (max-width: 600px) {
                        height: 15px;
                        margin-bottom: 0;
                        font-size: 12px;
                    }
                }

                &__prices {
                    display: flex;
                    justify-content: space-between;
                    font-family: $font-open-sans-bold;
                    line-height: 1;
                    align-items: flex-end;

                    @media (max-width: 600px) {
                        align-items: flex-end;
                        margin-top: 10px;
                    }

                    &--old-price {
                        display: flex;
                        flex-direction: column;
                        justify-self: flex-start;
                        font-family: $font-open-sans-bold;
                        color: $color-blue-dark;
                        font-size: 24px;
                        text-decoration: solid line-through $color-blue;
                        margin-bottom: 1px;

                        @media (max-width: 600px) {
                            font-size: 16px;
                        }
                    }

                    &--price {
                        font-family: $font-open-sans-bold;
                        color: $color-blue;
                        font-size: 33px;

                        @media (max-width: 600px) {
                            font-size: 22px;
                        }
                    }
                }
            }

            &__image-container {
                cursor: pointer;
                width: 247px;
                height: 247px;
                border-radius: 10px;
                background-position: top left;
                background-attachment: scroll;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;

                @media (max-width: 600px) {
                    width: 158px;
                    height: 158px;
                    border: 1px solid $color-light-grey;
                    margin-bottom: 5px;
                }

                &__overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba($color-blue, 0.71);
                    opacity: 0;
                    transition: opacity .15s;
                    font-family: $font-open-sans-semibold;
                    color: #ffffff;
                    font-size: 20px;

                    > img {
                        width: 42px;
                        height: 42px;
                        margin-left: 14px;
                    }
                }
            }
        }

    }

}
