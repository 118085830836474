.modal-custom {

    &__guest-register {
        .modal-dialog {
            max-width: 600px;
        }
    }

    &__no-borders {
        .modal-content {
            > * {
                border: none;
            }
        }
    }

    &.modal-product-image {
        img {
            height: 750px;
            width: 750px;
            display: block;
            margin: auto;
        }
    }

    .checkout-revision {
        .row {
            margin: 0;
        }

        .info-container {
            font-size: 16px;

            &.without-padding {
                padding-left: 0;
            }

            .bold {
                font-family: $font-open-sans-semibold;
            }
        }

        .price-container {
            text-align: right;
            font-size: 20px;

            &.small {
                font-size: 16px;
            }

            .bold {
                font-family: $font-open-sans-semibold;
            }
        }

        .store-cart-container {
            font-family: $font-open-sans;
            font-size: 16px;
            width: 100%;

            .store-name {
                font-family: $font-open-sans-semibold;
                font-size: 16px;
                margin-bottom: 0.25rem !important;
                margin-top: 0.825rem !important;
                color: $color-blue;
            }

            .line-container {
                width: 100%;
                display: inline-flex;
                padding: 0.25rem 1rem;

                img {
                    width: 75px;
                    height: 75px;
                }

                .line-info {
                    padding: 0 1rem;
                    width: 100%;
                    font-size: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .blue {
                        font-family: $font-open-sans;
                        color: $color-blue;
                    }

                    .product-title {
                        font-family: $font-open-sans-semibold;
                    }

                    .bold-blue {
                        font-family: $font-open-sans-semibold;
                        color: $color-blue;
                    }
                }
            }

            .store-divider {
                border-top: 2px solid rgba($color-blue, 0.5);
            }
        }
    }

    .courier-modal-body {
        &__image {
            width: 100%;
        }

        > h5 {
            white-space: pre-line;
        }
    }

    .guest-modal-body {
        >h4 {
            margin-bottom: 20px;
        }

        > h5 {
            font-size: 19px;
            line-height: 1.6;
            white-space: pre-line;
        }
    }

    .success-modal-header {
        .modal-title {
            flex: 1 1 auto;
        }

        .success-modal {
            padding: 2rem 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;

            .red-text {
                color: $color-red;
            }

            .nif-text {
                margin-top: 0.5rem !important;
                font-size: 20px;
            }
        }
    }

    ul {
        padding-left: 0;

        > .small-title {
            font-size: 15px;
            margin-bottom: 8px !important;
        }
    }

    &.order-modal {
        font-size: 16px;
        font-family: $font-open-sans;

        .bold {
            font-family: $font-open-sans-semibold;
        }

        .order-number {
            font-weight: bolder;
        }

        .store-name {
            font-weight: bolder;
        }

        .tax-price {
            width: 290px;

            @media (max-width: 990px) {
                width: initial;
            }
        }
    }

    &.store-form , &.product-form, &.claimant-modal {
        .rw-btn {
            vertical-align: unset;
        }

        .rw-input {
            padding: 0 0.5rem;
        }

        .schedule-add-btn-wrapper {
            margin-bottom: 1rem;
        }

        .variant-add-btn-wrapper {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        .button-align-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .banner-upload {
            width: 100%;

            .image-upload {
                display: flex;
                flex-direction: column;

                > img {
                    width: 100%;
                    height: auto;
                    max-height: 268px;
                    margin-bottom: 20px;
                }
            }

            > button {
                display: flex;
                justify-content: center;
                position: relative;
                left: 100px;
                bottom: 88px;
            }
        }

        .upload-container {
            max-height: 350px;
            overflow-y: scroll;

            &__upload-component {
                display: flex;

                .image-upload {
                    width: 240px;
                }

                > button {
                    align-self: flex-end;
                    margin-bottom: 45px;
                }
            }
        }

        .checkbox-container {
            > div {
                display: inline-flex;

                .error-field {
                    display: none;
                }

                a {
                    cursor: pointer;
                    font-weight: bold;
                    text-decoration: underline;
                    color: unset;
                }
            }

            .error-field {
                padding-left: 1rem;
                line-height: 1rem;
                color: red;
                font-size: 13px;
                font-family: $font-open-sans-semibold;
            }
        }

        .schedule-row {
            .schedule-remove-column {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .button {
                    width: fit-content;
                    width: -moz-fit-content;
                }
            }
        }

        .trash-icon-cell {
            svg {
                width: 18px;
                height: 18px;
                fill: rgba($color-red, 0.8);
            }
        }

        .variant-container {
            background-color: rgba($color-blue, 0.03);
            padding-left: 0.825rem !important;
            padding-right: 0.825rem !important;
            padding-bottom: 0.825rem !important;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            &.error {
                background-color: rgba($color-red, 0.06);
            }
        }

        .characteristic-row {
            .characteristic-remove-column {
                display: inline-flex;
                align-items: flex-end;
                padding-bottom: 1rem;
            }
        }

        .info-bold {
            font-family: $font-open-sans-semibold;
        }

        ul {
            margin-top: 10px;

            .small-title {
                color: $color-blue-dark;
                font-family: $font-open-sans-semibold;
                padding-left: 5px;
            }

            svg {
                fill: $color-red;
            }

            li {
                font-family: $font-open-sans;
                cursor: pointer;

                .bold {
                    font-family: $font-open-sans-bold;
                    color: black;
                }
            }
        }
    }

    .subcategory-item {
        cursor: pointer;

        p {
            font-family: $font-open-sans;
            font-size: 13px;
        }

        .svg-icon {
            svg {
                width: 15px;
                height: 15px;
                fill: rgba($color-red, 0.8);
            }
        }
    }

    .footer-buttons-container {
        button:not(:last-of-type) {
            margin-right: 5px;
        }
    }
}
