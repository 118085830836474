.sidebar-blackout {
    position: fixed;
    z-index: $blackout-z-index;
    top: 0;
    left: 0;
    width: 100vw;
    height: 101%;
    overflow-y: hidden;
    background-color: black;
    transition: opacity 0.5s, visibility 0.5s;

    &--hide {
        visibility: hidden;
        opacity: 0;
    }

    &--show {
        visibility: visible;
        opacity: 0.6;
    }
}

.sidebar {
    display: flex;
    position: fixed;
    z-index: $sidebar-z-index;
    top: 0;
    flex-direction: column;
    width: 530px;
    height: 100%;
    background-color: $color-blue-dark;
    transition: left .5s;

    &--hide {
        left: -960px;
    }

    &--show {
        left: 0;
    }

    @media (max-width: 991px) {
        width: 350px;
    }

    &__divider {
        width: 480px;
        margin: 0;
        border-width: 1px;
        border-color: $color-blue;

        @media (max-width: 991px) {
            width: 350px;
            margin-bottom: 20px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        margin: 37px 75px 0 53px;

        @media (max-height: 810px) {
            margin-top: 20px;
        }

        @media (max-width: 991px) {
            margin: 10px 20px 0 10px;
        }

        &__categories-section {
            display: flex;
            flex-direction: column;
            font-family: $font-open-sans;
            font-size: 25px;
            letter-spacing: .75px;
            color: #ffffff;

            @media (max-width: 991px) {
                font-size: 18px;
            }

            &__items-container {
                display: flex;
                flex-direction: column;
                max-height: 70vh;
                overflow-y: scroll;

                &__item {
                    max-width: 270px;
                    margin-bottom: 20px;
                    font-family: $font-open-sans;
                    font-size: 20px;
                    letter-spacing: .75px;
                    color: #ffffff;

                    @media (max-width: 991px) {
                        font-size: 18px;
                        margin-bottom: 20px;
                    }

                    @media (max-height: 760px) {
                        margin-bottom: 18px;
                    }

                    &:hover {
                        text-decoration: unset;
                        color: $color-blue;
                    }
                }
            }

            &__title {
                display: flex;
                margin-left: 20%;
                font-family: $font-open-sans-bold;
                color: $color-blue;
                cursor: pointer;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 32px;

            &__logo {
                width: 243px;
                height: 73px;

                @media (max-width: 991px) {
                    width: 170px;
                    height: auto;
                }
            }

            &__close-button {
                width: 15px;
                height: 15px;
                cursor: pointer;
            }
        }

        &__links {
            display: flex;
            flex-direction: column;
            margin-bottom: 11px;
            padding-left: 24px;

            &__item {
                max-width: 270px;
                margin-bottom: 35px;
                font-family: $font-open-sans;
                font-size: 25px;
                letter-spacing: .75px;
                color: #ffffff;
                cursor: pointer;
                text-align: left;

                @media (max-width: 991px) {
                    font-size: 18px;
                    margin-bottom: 20px;
                }

                @media (max-height: 770px) {
                    margin-bottom: 18px;
                }

                &:hover {
                    text-decoration: unset;
                    color: $color-blue;
                }
            }
        }

        &__button {
            margin-top: 44px;

            @media (max-width: 991px) {
                height: 30vh;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }

            @media (max-height: 760px) {
                margin-top: 40px;
            }

            @media (max-height: 640px) {
                margin-top: 0px;
            }

            .btn-light-blue {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 381px;
                height: 56px;
                padding: 11px 20px 12px 20px;
                background-color: $color-blue;
                border: 2px solid $color-blue;
                font-family: $font-open-sans-semibold;
                color: #ffffff;
                font-size: 24px;
                letter-spacing: .72px;
                border-radius: 30px;

                @media (max-width: 991px) {
                    font-size: 20px;
                    width: 310px;
                    height: auto;
                }

                &:hover {
                    background-color: #FFFFFF;
                    color: $color-blue;
                }
            }
        }
    }
}
