.sort-filter-button {
    > div {
        display: flex;
        justify-content: center;
        min-width: 102px;
        padding: 8px 18px 8px 18px;
        font-family: $font-open-sans-semibold;
        font-size: 12px;
        letter-spacing: .36px;
        border-radius: 34px;
        cursor: pointer;

        > img {
            margin-left: 8px;
        }
    }

    &__inactive {
        color: $color-grey;
        border: 1px solid $color-light-grey;

        &--ascending {
            color: $color-grey;
            border: 1px solid $color-light-grey;
        }

        &--descending {
            color: $color-grey;
            border: 1px solid $color-light-grey;

            > img {
                transform: scaleY(-1);
            }
        }
    }

    &__active {
        color: $color-blue;
        border: 1px solid $color-blue;

        &--ascending {
            color: $color-blue;
            border: 1px solid $color-blue;

            > img {
                filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
            }
        }

        &--descending {
            color: $color-blue;
            border: 1px solid $color-blue;

            > img {

                transform: scaleY(-1);
                filter: invert(56%) sepia(93%) saturate(873%) hue-rotate(166deg) brightness(89%) contrast(97%);
            }
        }
    }
}