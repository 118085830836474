html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
}

body {
    font-family: $font-open-sans;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    border: none;

    &:focus {
        outline-offset: unset;
        outline: none;
    }
}

.field-error {
    font-family: $font-open-sans-bold;
    font-size: 13px;
    color: red;
    margin: 0;

    .general {
        margin-bottom: 5px;
    }

    &--centered {
        text-align: center;
    }
}

// PAGING
.paging {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    font-family: $font-open-sans-semibold;
    font-size: 14px;

    button {
        justify-content: center;
        align-items: center;
        min-width: 34px;
        height: 34px;
        margin: 0;
        text-align: center;
        color: rgba($color-blue-dark, 0.4);

        .svg-icon {
            width: 10px;
            height: 10px;

            svg {
                width: 10px;
                height: 10px;
                fill: rgba($color-blue-dark, 0.4);
            }
        }

        &:hover {
            color: $color-blue-dark;
        }
    }


    .pages {
        display: flex;

        .page,
        .more {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 34px;
            height: 34px;
            margin: 0;
            text-align: center;
            color: rgba($color-blue-dark, 0.2);
        }

        .page {
            color: $color-blue-dark;
            //font-weight: bold;
        }
    }
}

// SEARCH
.search-field {
    padding: 0.25rem 0.5rem;
    display: inline-flex;
    width: 300px;

    > div {
        width: 100%;

        > div {

            :nth-child(-n + 2) {
                font-family: $font-open-sans-bold;
                font-size: 16px;
                color: $color-blue;
            }

            > div {
                display: flex;
                padding: 3px;
                cursor: pointer;

                > img {
                    width: 25px;
                    height: 25px;
                    margin: 0 10px 0 5px;
                    filter: brightness(0) saturate(100%) invert(12%) sepia(14%) saturate(4422%) hue-rotate(203deg) brightness(96%) contrast(89%);
                }
            }
        }
    }

    input {
        width: 100%;
        flex: 1 1 auto;
        font-family: $font-open-sans-semibold;
        color: $color-blue;
    }

    .search-result {
        font-family: $font-open-sans-semibold;
        font-size: 16px;
        color: $color-blue-dark;
    }

    .svg-icon {
        padding-left: 5px;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-blue;
        }
    }
}

// PRODUCTS QUANTITY CONTROLS
.quantity-controls {
    display: inline-flex;
    padding-top: 8px;
    width: 120px;

    .controls {
        background-color: #FFFFFF;
        width: 28px;
        height: 35px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid $color-light-grey;

        &.minus {
            border-radius: 17px 0 0 17px;
            border-right: none;

            &.disabled {
                background-color: $color-light-grey;
                color: $color-grey;
                border: 2px solid $color-light-grey;
                cursor: default;

                &:hover {
                    background-color: $color-light-grey;
                    color: $color-grey;
                }
            }
        }

        &.plus {
            border-radius: 0 17px 17px 0;
            border-left: none;

            &.disabled {
                background-color: $color-light-grey;
                color: $color-grey;
                border: 2px solid $color-light-grey;
                cursor: default;

                &:hover {
                    background-color: $color-light-grey;
                    color: $color-grey;
                }
            }
        }

        .svg-icon {
            span {
                display: inline-flex;

                svg {
                    width: 13px;
                    height: 13px;
                    fill: $color-blue;
                    stroke-width: 15px;
                }
            }
        }

    }

    .input-container {
        flex: 1;
        height: 35px;
        font-size: 15px;
        font-family: $font-open-sans-semibold;
        border: 1px solid $color-light-grey;
        border-right: none;
        border-left: none;

        input {
            color: $color-blue-dark;
            height: 100%;
            width: 100%;
            background-color: rgba(#FFFFFF, 0.2);
            text-align: center;

            &.disabled {
                background-color: $color-light-grey;
                color: $color-grey;
                border: 2px solid $color-light-grey;
                cursor: default;
            }

        }

        &.disabled {
            background-color: $color-light-grey;
            color: $color-grey;
            border: 2px solid $color-light-grey;
            cursor: default;
        }
    }
}

.form-control:disabled {
    background-color: rgba($color-light-grey, 0.4) !important;
}

.terms-checkbox-container {
    margin-bottom: 1rem;

    p {
        color: rgba(#FFFFFF, 0.95);
        font-family: $font-open-sans;
    }

    .form-check-input {
        margin-top: .5rem;
    }

    > div {
        display: inline-flex;

        .error-field {
            display: none;
        }

        a {
            cursor: pointer;
            font-family: $font-open-sans-semibold;
            text-decoration: underline;
            color: unset;
            font-size: 18px;
        }
    }

    .error-field {
        padding-left: 1rem;
        line-height: 1rem;
        color: red;
        font-size: 15px;
        margin-bottom: 1rem !important;
        font-family: $font-open-sans-semibold;
    }
}

.svg-icon {
    width: 20px;
    &--red {
        fill: red;
    }
    &--blue {
        fill: $color-blue;
    }
}

.message {
    margin-top: 60px;
    padding: 1em;
    box-shadow: 0 0 10px $color-shadow;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    max-width: 510px;
    text-align: center;
    font-size: 20px;

    hr {
        width: 100%;
    }
}

.gap-4 {
    gap: 1em;
}

.not-clickable {
    cursor: default;
}