.button {
    font-family: $font-open-sans-semibold;
    display: inline-block;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    height: 36px;
    padding: 0 15px;
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    border: none;
    border-radius: 23px;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: normal;
    text-align: center;

    &--dark-blue {
        display: inline-flex;
        align-items: center;
        background-color: $color-blue-dark;
        color: #FFFFFF;
        border: 2px solid $color-blue-dark;

        &:hover {
            background-color: #FFFFFF;
            color: $color-blue-dark;

            .svg-icon {
                svg {
                    filter: brightness(0);
                }
            }
        }
    }

    &--blue {
        display: inline-flex;
        align-items: center;
        background-color: $color-blue;
        color: #FFFFFF;
        border: 2px solid $color-blue;

        &:hover {
            background-color: #FFFFFF;
            color: $color-blue;
        }
    }

    &--light-red {
        display: inline-flex;
        align-items: center;
        background-color: $color-light-red;
        color: #FFFFFF;
        border: 2px solid $color-light-red;

        &:hover {
            background-color: #FFFFFF;
            color: $color-light-red;
        }
    }

    &--small {
        font-size: 14px;
    }

    &--smaller {
        font-size: 12px;
        height: 26px;
        border-radius: 13px;
    }

    &--bigger {
        height: 60px;
        font-size: 20px;
        border-radius: 30px;
    }

    &--link {
        color: #ffffff;
        text-transform: none;
        text-decoration: underline;
        border: unset;
    }

    &--blue-link {
        font-family: $font-open-sans;
        color: $color-blue-link;
        text-transform: none;
        text-decoration: none;
        border: unset;
        background-color: transparent;
        padding: 0;
        font-size: 14px;

        &:focus {
            outline: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &--disabled {
        background-color: $color-light-grey;
        color: $color-grey !important;
        border: 2px solid $color-light-grey;
        cursor: default;

        &:hover {
            background-color: $color-light-grey;
            color: $color-grey !important;
        }
    }

    &--pull-right {
        position: absolute;
        right: 0;
        z-index: 2;
    }

    &--with-icon {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 40px;
        text-transform: uppercase;

        &--left {
            padding-left: 10px;

            .svg-icon {
                display: block;
                order: 1;
                margin: 0 20px 0 0;
            }

            > img {
                display: block;
                order: 1;
                margin: 0 20px 0 0;
            }
        }

        &--right {
            padding-right: 20px;

            .svg-icon {
                margin: 0 0 0 20px;
            }

            > img {
                display: block;
                order: 2;
                margin: 0 0 0 20px;
            }
        }
    }

    > span {
        order: 2;
    }

    .svg-icon {
        display: inline-block;
        width: 15px;
        margin-bottom: 3px;

        svg {
            width: 15px;
            height: 15px;
        }
    }
}
