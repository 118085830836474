.home-screen {
    width: 100%;
    overflow-x: hidden;

    .carousel-indicators {
        margin-bottom: 32px;

        > li {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 1px solid $color-blue-dark;
            background-color: transparent;
            background-clip: unset;
            opacity: 1;
        }

        .active {
            background-color: $color-blue-dark;
        }

        @media (max-width: 991px) {
            margin-bottom: 40px;

            > li {
                width: 10px;
                height: 10px;
            }
        }
    }

    .btn {
        height: 60px;
        border-radius: 30px;
        color: #FFFFFF;
        font-size: 22px;
        font-weight: 600;
        padding: 11px 40px 12px 40px;
        letter-spacing: 1px;
        font-family: $font-open-sans-semibold;
    }

    h2 {
        font-size: 2.5rem;
    }

    @media (max-width: 600px) {
        .carousel-control-prev, .carousel-control-next {
            display: none;
        }
    }

    &__tagline {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 44px;
        margin-bottom: 40px;

        &--button {
            margin: 0 0 0 0;
        }

        @media (max-width: 991px) {
            width: 100vw;
        }

        .btn-light-blue {
            width: 195px;
            height: 56px;
            padding: 11px 20px 12px 20px;
            background-color: $color-blue;
            border: 2px solid $color-blue;
            font-size: 20px;
            letter-spacing: .6px;

            &:hover {
                background-color: #FFFFFF;
                color: $color-blue;
            }
        }

        > h3 {
            margin-bottom: 13px;
            font-family: $font-open-sans-bold;
            font-size: 25px;
            letter-spacing: .75px;
            color: $color-blue-dark;
            text-align: center;

            @media (max-width: 991px) {
                width: 75%;
                margin-bottom: 30px;
                font-size: 20px;
            }
        }

        > p {
            width: 1112px;
            font-size: 16px;
            text-align: center;
            color: $color-grey;
            margin-bottom: 0;

            @media (max-width: 991px) {
                width: 80%;
                font-size: 16px;
            }
        }

        &__p-regular {
            font-family: $font-open-sans;
        }

        &__p-bold {
            font-family: $font-open-sans-bold;
        }
    }

    &__carousel-container {
        position: relative;
        width: 100%;

        > .search-container {
            position: absolute;
            top: 0;
            width: 100%;
            color: #FFFFFF;
            display: inline-flex;
            justify-content: center;
            padding: 10px;
            z-index: 10;

            .search {
                width: 80%;
                display: inline-flex;
                align-items: center;

                .search-field {
                    background-color: #FFFFFF;
                    flex: 1 1 auto;
                    margin-right: 10px;
                    height: 36px;
                    border-radius: 23px;
                    border-bottom: none;
                    padding: 0 1rem;
                    display: inline-flex;
                    align-items: center;

                    .vertical-divider {
                        height: 30px;
                        border: 1px solid $color-blue;
                        background-color: $color-blue;
                    }

                    select {
                        text-align: center;
                        border: none;
                        min-width: 160px;
                        padding-left: 2rem;
                        background-image: url('../../../images/icon-down_arrow_blue.svg');
                        background-position: right;
                        background-size: 15px 15px,
                        15px 15px,
                        1.5em 1.5em;
                        background-repeat: no-repeat;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                    }
                }

                @media (max-width: 660px) {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .search-field {
                        width: 100%;
                        margin-right: 0;
                    }

                    .button {
                        margin-top: 7px;
                    }
                }

                @media (max-width: 425px) {
                    .search-field {
                        select {
                            min-width: 100px;
                            padding-left: 0.5rem;
                        }
                    }
                }

                @media (max-width: 355px) {
                    .search-field {
                        padding: 0 0.5rem;
                        flex: unset;
                    }
                }

                @media (max-width: 345px) {
                    .search-field {
                        padding: 0 0.5rem;
                        flex: 1 1 auto;
                        font-size: 14px;

                        input {
                            max-width: 180px;
                        }

                        select {
                            min-width: 90px;
                            padding-left: 0.25rem;
                        }
                    }
                }
            }
        }

        &__subheader {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            height: 100px;
            background-color: $color-blue;
            padding: 0 350px 0 350px;

            @media (max-width: 1200px) {
                justify-content: center;
                padding: 0;
            }

            .slide {
                min-width: 370px;
                height: 59px;
            }

            .carousel-item {
                margin-left: 10px;

                .carousel-caption {
                    height: 59px;
                    margin-left: 10px;
                    text-align: left;
                }
            }

            &__item {
                width: 391px;
                height: 59px;
                display: flex;
                align-items: center;

                &__image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 59px;
                    height: 59px;
                    background-color: #ffffff;
                    border-radius: 50%;
                    margin-right: 15px;

                    > img {
                        display: block;
                        height: 35px;
                        margin-top: auto;
                        margin-bottom: auto;
                    }
                }

                &__caption {
                    display: flex;
                    flex-direction: column;
                    width: 317px;
                    color: #ffffff;

                    > * {
                        margin: 0;
                        font-size: 15px;

                        @media (max-width: 1200px) {
                            font-size: 14px;
                        }
                    }

                    > h5 {
                        font-family: $font-open-sans-bold;
                    }

                    > h6 {
                        font-family: $font-open-sans-light;
                    }
                }
            }
        }

        &__header {
            padding-top: 3rem;
            height: 60vh;

            &__carousel {
                height: 568px;
                cursor: pointer;

                .carousel-caption {
                    color: unset;
                    display: flex;
                    justify-content: center;
                }

                &__carousel-image {
                    position: absolute;
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-size: cover;
                    width: 100%;
                    height: 568px;

                    &--right {
                        background-position: right;
                    }

                    &--left {
                        background-position: left;
                    }

                    @media (max-width: 550px) {
                        width: 100vw;
                        background-position: center 70%;
                        background-size: cover;
                    }
                }

                &__carousel-item {
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center;
                    background-size: cover;
                    height: 568px;

                    &.custom {
                        .carousel-caption {
                            width: 600px;
                            margin-left: 40px;
                        }

                        @media (max-width: 550px) {
                            .carousel-caption {
                                right: 0;
                                left: 0;
                                width: unset;
                                margin-left: unset;
                            }
                        }
                    }

                    .right {
                        align-items: flex-end;
                        text-align: right;

                        @media (max-width: 1366px) {
                            align-items: flex-start;
                            text-align: left;
                        }
                    }

                    &__caption {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        width: 945px;
                        height: 365px;
                        font-family: $font-open-sans-bold;
                        text-align: left;
                        color: $color-blue-dark;
                        margin-left: 40px;

                        .btn {
                            margin-top: 10px;
                        }

                        &.custom {
                            margin-left: 0;

                            @media (max-width: 1550px) {
                                margin-left: -10%;
                                align-items: flex-start;

                                > h5 {
                                    width: 60%;
                                }
                            }

                            @media (max-width: 550px) {
                                margin-left: 0;
                                width: 90vw;
                                height: 425px;
                                justify-content: flex-start;
                                align-items: flex-start;

                                > a {
                                    margin-top: 10px;
                                }
                            }

                            > h1 {
                                font-family: $font-open-sans-semibold;
                                text-transform: unset;
                                font-size: 40px;
                                letter-spacing: 1px;
                                margin-bottom: 10px;
                                max-width: 500px;

                                @media (max-width: 550px) {
                                    max-width: 300px;
                                    font-size: 16px;
                                }
                            }

                            > h5 {
                                text-transform: unset;
                                font-size: 26px;
                                letter-spacing: 1px;

                                @media (max-width: 550px) {
                                    margin-bottom: 5px;
                                    font-size: 15px;
                                }
                            }
                        }

                        .btn-light-blue {
                            width: 195px;
                            height: 56px;
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: $color-blue;
                            border: 2px solid $color-blue;
                            font-size: 20px;
                            letter-spacing: .6px;

                            &:hover {
                                background-color: #FFFFFF;
                                color: $color-blue;
                            }

                            @media (max-width: 550px) {
                                width: 150px;
                                height: 45px;
                                margin-top: 30px;
                                font-family: $font-open-sans-light;
                                font-size: 17px;
                            }
                        }

                        .regular {
                            font-family: $font-open-sans;
                        }

                        > h5 {
                            font-size: 17px;
                            letter-spacing: 1.7px;
                            text-transform: uppercase;
                            color: #ffffff;

                            @media (max-width: 500px) {
                                font-size: 12px;
                            }
                        }

                        > h1 {
                            font-size: 40px;
                            letter-spacing: 1.2px;

                            @media (max-width: 500px) {
                                font-size: 25px;
                            }
                        }

                        > h4 {
                            font-family: $font-open-sans;
                            font-size: 20px;
                            letter-spacing: 2px;

                            @media (max-width: 500px) {
                                font-size: 18px;
                                letter-spacing: .45px;
                            }
                        }
                    }
                }
            }

            &.centered {
                display: inline-flex;
                width: 100%;
                justify-content: center;

                .container {
                    display: flex;
                    justify-content: flex-end;
                    flex-direction: column;

                    &__bottom {
                        height: 64%;
                    }
                }
            }

            &.viva-leiria {
                background: linear-gradient(to bottom, rgba(35, 40, 78, 0.8) 0%, rgb(35, 40, 78) 100%), url('../../../images/bg_header_viva_leiria.jpg');
            }

            @media (max-height: 792px) and (max-width: 770px) {
                padding-top: 89px;
                padding-bottom: 20px;
                height: 85vh;
            }

            @media (max-height: 792px) and (max-width: 645px) {
                height: 90vh;
            }

            @media (max-height: 689px) and (max-width: 466px) {
                height: 95vh;
            }

            @media (max-height: 649px) and (max-width: 466px) {
                height: 110vh;
            }

            @media (max-height: 590px) and (max-width: 466px) {
                height: 100vh;

                h1 {
                    font-size: 2rem;
                }

                p.mb-5 {
                    margin-bottom: 1rem !important;
                }

                .btn {
                    height: 36px;
                    font-size: 18px;
                    line-height: 18px;
                    border-radius: 23px;
                    padding: 5px 20px;

                    &.big-button {
                        height: 52px;
                    }
                }
            }

            @media (max-height: 500px) and (max-width: 466px) {
                height: 105vh;
            }

            .btn-light-blue {
                background-color: $color-blue;
                border: 2px solid $color-blue;

                &:hover {
                    background-color: #FFFFFF;
                    color: $color-blue;
                }
            }

            p {
                font-size: 18px;
                color: rgba(255, 255, 255, 0.75);
                font-family: $font-open-sans-semibold;
            }

            hr.divider {
                border-width: 0.2rem;
                max-width: 3.25rem;
                border-color: $color-blue;
            }

            .title {
                font-family: $font-open-sans-bold;
                color: #FFFFFF;
                max-width: 800px;
            }

            .subtitle {
                font-family: $font-open-sans-bold;
                color: #FFFFFF;
                font-size: 1rem;
                text-transform: uppercase;
                margin-bottom: 1rem !important;
            }

            .text-container {
                width: 80%;
            }

            @media (max-width: 700px) {
                width: 100%;
            }

            @media (max-width: 400px) {
                text-align: center;
            }
        }
    }

    &__commerce {
        padding: 8rem 0;
        background-color: $color-blue;

        h2 {
            color: #FFFFFF;
        }

        h2:first-of-type {
            margin-bottom: 8px !important;
        }

        p {
            font-size: 18px;
            font-family: $font-open-sans;
            color: rgba(255, 255, 255, 0.75);
        }

        hr.divider {
            border-width: 0.2rem;
            max-width: 3.25rem;
            border-color: #FFFFFF;
        }

        .bold {
            font-family: $font-open-sans-bold;
            font-weight: bolder;
        }

        .btn-dark-blue {
            background-color: $color-blue-dark;
            border: 2px solid $color-blue-dark;

            &:hover {
                background-color: #FFFFFF;
                color: $color-blue-dark;
            }
        }
    }

    &__benefits {
        padding: 4rem 0;
        color: $color-blue-dark;

        h3 {
            font-size: 30px;
        }

        p {
            font-size: 18px;
            color: $color-grey;
            font-family: $font-open-sans;
        }

        img {
            height: 65px;
        }

        hr.divider {
            border-width: 0.2rem;
            max-width: 3.25rem;
            border-color: $color-blue;
        }

        .delivery-home {
            position: relative;

            img {
                opacity: 0.4;
            }

            .soon-container {
                position: absolute;
                font-family: $font-open-sans-bold;
                color: $color-blue-dark;
                top: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-top: 1rem;
                z-index: 10;
                font-size: 22px;
                transform: rotate(-20deg);
            }
        }
    }

    &__sponsors {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8rem 0;
        color: $color-blue-dark;

        h3 {
            display: block;
            font-family: $font-open-sans-semibold;
            color: $color-blue-dark;
            font-size: 25px;
            letter-spacing: .75px;
            margin-bottom: 42px;

            @media (max-width: 1100px) {
                font-family: $font-open-sans-bold;
                font-size: 20px;
            }
        }

        &__image-container {
            width: 1148px;
            display: flex;
            align-items: center;

            > a {
                max-width: 153px;
            }

            @media (max-width: 1100px) {
                width: 90vw;
                display: grid;
                grid-template-columns: 33% 33% 33%;

                > :last-child {
                    grid-column: 2;
                }

                > a {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &__contacts {
        padding: 8rem 0;

        hr.divider {
            border-width: 0.2rem;
            max-width: 3.25rem;
            border-color: $color-blue;
        }

        h2 {
            font-size: 3rem;
        }

        p {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.75);
            font-family: $font-open-sans;
        }

        .form-control {
            font-size: 18px;
            font-family: $font-open-sans;
        }

        .button {
            margin-top: 1rem;
            padding: 11px 40px;
            font-size: 22px;
        }

        .recaptcha-error {
            font-family: $font-open-sans-bold;
            color: $color-red;
        }

        .terms-checkbox-container {
            margin-bottom: 1rem;

            p {
                color: rgba(#FFFFFF, 0.95);
                font-family: $font-open-sans;
            }

            .form-check-input {
                margin-top: .5rem;
            }

            > div {
                display: inline-flex;

                .error-field {
                    display: none;
                }

                a {
                    cursor: pointer;
                    font-family: $font-open-sans-semibold;
                    text-decoration: underline;
                    color: unset;
                    font-size: 18px;
                }
            }

            .error-field {
                padding-left: 1rem;
                line-height: 1rem;
                color: red;
                font-size: 15px;
                margin-bottom: 1rem !important;
                font-family: $font-open-sans-semibold;
            }
        }
    }

    &__small-carousel {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        max-width: 1900px;
        margin-top: 27px;
        height: 400px;
        overflow-x: hidden;

        @media (max-width: 500px) {
            width: 100vw;
        }

        &--product {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            margin-top: 27px;
            margin-bottom: 60px;

            .slick-slider {
                width: 100vw;
                max-width: 1200px;
                height: 400px;

                .slick-list {
                    height: 400px;
                }

                @media (max-width: 1183px) {
                    width: 90vw;
                }

                @media (max-width: 800px) {
                    height: 440px;

                    .slick-list {
                        height: 440px;
                    }
                }

                @media (max-width: 500px) {
                    width: 100vw;
                    height: 300px;

                    .slick-list {
                        width: 100vw;
                        height: 300px;
                    }
                }

                > ul {
                    list-style: none;
                    display: none;

                    > li {
                        margin-top: 41px;
                        position: unset;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        border: 1px solid $color-blue-dark;
                        background-color: #ffffff;
                        background-clip: unset;
                        opacity: 1;

                        > button::before {
                            display: none;
                        }
                    }

                    @media (max-width: 991px) {
                        margin-bottom: 0;

                        > li {
                            width: 12px;
                            height: 12px;
                        }
                    }

                    .slick-active {
                        > button {
                            opacity: 0;
                        }

                        background-color: $color-blue-dark;
                    }
                }
            }

            .slick-track {
                height: 320px;
            }

            @media (max-width: 980px) {
                margin-bottom: 0;
            }
        }

        .btn-light-blue {
            width: 195px;
            height: 56px;
            padding: 11px 20px 12px 20px;
            margin-top: 64px;
            background-color: $color-blue;
            border: 2px solid $color-blue;
            font-size: 20px;
            letter-spacing: .6px;

            &:hover {
                background-color: #FFFFFF;
                color: $color-blue;
            }
        }

        .slick-slider {
            width: 100vw;
            max-width: 1200px;

            @media (max-width: 1280px) {
                width: 90vw;
            }

            @media (max-width: 500px) {
                width: 100vw;
            }

            > ul {
                list-style: none;
                display: none;

                > li {
                    margin-top: 41px;
                    position: unset;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 1px solid $color-blue-dark;
                    background-color: #ffffff;
                    background-clip: unset;
                    opacity: 1;

                    > button::before {
                        display: none;
                    }
                }

                @media (max-width: 991px) {
                    margin-bottom: 40px;

                    > li {
                        width: 12px;
                        height: 12px;
                    }
                }

                .slick-active {
                    > button {
                        opacity: 0;
                    }

                    background-color: $color-blue-dark;
                }
            }
        }

        .slick-track {
            height: 370px;

            @media (max-width: 980px) {
                height: 400px;
            }
        }

        .slick-slide {
            > div {
                > div {
                    display: flex;
                    justify-content: center;
                }
            }
        }

        &__item-container {
            display: flex !important;
            justify-content: center !important;

            &--product {
                display: flex !important;
                justify-content: center !important;
                min-height: 170px;
                cursor: pointer;

                &__item {
                    width: 267px;
                    box-shadow: 0 5px 5px $color-shadow;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;

                    &__caption-container {
                        flex-direction: column;
                        padding: 14px 20px 14px 20px;

                        &__prices {
                            display: grid;
                            grid-template-columns: 50% 50%;
                            justify-content: flex-start;
                            font-family: $font-open-sans-bold;
                            line-height: 1;
                            align-items: flex-end;
                            margin-top: 10px;

                            > :nth-child(2) {
                                text-align: right;
                            }

                            &--old-price {
                                display: flex;
                                flex-direction: column;
                                justify-self: flex-start;
                                font-family: $font-open-sans-bold;
                                color: $color-blue-dark;
                                font-size: 25px;
                                text-decoration: line-through;
                                text-decoration-color: $color-blue;
                                margin-right: 20px;
                            }

                            &--price {
                                font-family: $font-open-sans-bold;
                                color: $color-blue;
                                font-size: 33px;
                            }

                            @media (max-width: 500px) {
                                &--old-price {
                                    font-size: 18px;
                                }

                                &--price {
                                    font-size: 20px;
                                }
                            }

                            @media (max-width: 400px) {
                                &--old-price {
                                    font-size: 16px;
                                }

                                &--price {
                                    font-size: 16px;
                                }
                            }
                        }
                    }

                    @media (max-width: 800px)  {
                        width: 40vw;
                    }

                    &__image-container {
                        position: relative;

                        .discount-box {
                            position: absolute;
                            left: 20px;
                            z-index: $home-screen-promo-z-index;
                        }

                        div[role=img] {
                            position: relative;
                            margin: auto;
                            width: 247px;
                            height: 246px;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                            background-repeat: no-repeat;
                            background-size: cover;
                            background-position: center center;
                        }

                        @media (max-width: 800px) {
                            div[role=img] {
                                width: 40vw;
                                height: 40vw;
                            }
                        }
                    }
                }
            }

            &__item {
                width: 361px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                box-shadow: 0 5px 5px $color-shadow;
                cursor: pointer;

                @media (max-width: 1280px) {
                    width: 320px;
                }

                @media (max-width: 1024px) {
                    width: 300px;
                }

                @media (max-width: 991px) {
                    width: 361px;
                }

                > img {
                    width: 361px;
                    height: 245px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    object-fit: cover;

                    @media (max-width: 1280px) {
                        width: 320px;
                    }

                    @media (max-width: 1024px) {
                        width: 300px;
                    }

                    @media (max-width: 991px) {
                        width: 361px;
                    }
                }

                &__caption-container {
                    display: flex;
                    justify-content: space-between;
                    padding: 14px 20px 14px 20px;

                    &__text {
                        max-width: 250px;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;

                        > * {
                            margin: 0;
                        }

                        > p {
                            font-family: $font-open-sans-bold;
                            font-size: 10px;
                            max-height: 15px;
                            max-width: 240px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            color: $color-blue;
                            margin-bottom: 5px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        > h4 {
                            margin-bottom: 0;
                            font-family: $font-open-sans-bold;
                            font-size: 18px;
                            letter-spacing: .54px;
                            color: $color-blue-dark;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            @media (max-width: 400px) {
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

    &__categories {
        color: $color-blue-dark !important;

        .carousel-top {
            width: 100%;
            display: inline-flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 400px) {
                //display: flex;
                //flex-direction: column;
                //align-items: flex-end;
            }
        }

        .carousel-title {
            display: inline-flex;
            padding-left: 1rem;

            @media (max-width: 500px) {
                display: flex;
                flex-direction: column;

                .see-all-container {
                    margin-left: 0 !important;
                }
            }

            h4 {
                font-family: $font-open-sans-semibold;
                font-size: 20px;
            }

            .see-all-container {
                display: inline-flex;
                margin-left: 15px;
                font-size: 15px;
                align-items: flex-end;
                font-family: $font-open-sans;
                cursor: pointer;

                > p {
                    white-space: nowrap;
                }

                svg {
                    margin-left: 5px;
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .category-item {
            margin: 0 1rem;
            display: inline-flex;
            justify-content: center;
            height: 100%;
            cursor: pointer;

            .card-img {
                align-self: center;
            }

            @media (min-width: 1200px) {
                .card {
                    width: 323px;

                    img {
                        width: 320px;
                        height: 320px;
                    }
                }
            }

            @media (max-width: 1200px) {
                .card {
                    width: 283px;

                    img {
                        width: 280px;
                        height: 280px;
                    }
                }
            }

            @media (max-width: 992px) {
                .card {
                    width: 213px;

                    img {
                        width: 210px;
                        height: 210px;
                    }
                }
            }

            @media (max-width: 992px) {
                .card {
                    width: 213px;

                    img {
                        width: 210px;
                        height: 210px;
                    }
                }
            }

        }

        @media (max-width: 500px) {
            .slide.selected {
                display: inline-flex;
                justify-content: center;
            }
        }

        .carousel .slider-wrapper.axis-horizontal .slider {
            display: flex;
        }

        .carousel .slider.animated {
            transition: all .35s ease-in-out;
        }

        .carousel .slider {
            margin: 0;
            padding: 0;
            position: relative;
            list-style: none;
            width: 100%;
        }

        .carousel .slider-wrapper {
            overflow: hidden;
            margin: auto;
            width: 100%;
            transition: height .15s ease-in;
        }

        .carousel.carousel-slider {
            position: relative;
            margin: 0;
            overflow: hidden;
        }

        .controls-top {
            padding-right: 2rem;
            display: inline-flex;

            .svg-icon {
                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}