.store-screen {
    display: flex;
    flex-direction: column;
    align-items: center;

    .tagline {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 44px;
        margin-bottom: 40px;

        > h3 {
            margin-bottom: 13px;
            font-family: $font-open-sans-bold;
            font-size: 25px;
            letter-spacing: .75px;
            color: $color-blue-dark;
        }

        > p {
            max-width: 1112px;
            font-size: 16px;
            text-align: justify;
            color: $color-grey;
            margin-bottom: 0;
            white-space: pre-line;
        }

        &__p-regular {
            font-family: $font-open-sans;
        }

        &__p-bold {
            font-family: $font-open-sans-bold;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        height: 399px;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;

        @media (max-width: 1024px) {
            width: 100vw;
            height: 250px;
            background-position: center;
        }

        &__titles {
            width: 100%;
            max-width: 950px;
            font-family: $font-open-sans-bold;
            letter-spacing: 1.2px;
            text-shadow: 1px 1px $color-grey-dark;
            color: #ffffff;

            @media (max-width: 1024px) {
                width: 90%;
            }

            &__links {
                width: 100%;
                display: flex;
                font-family: $font-open-sans;
                font-size: 10px;
                margin-bottom: 5px;

                > * {
                    color: #ffffff;
                }

                &--no-banner {
                    > * {
                        color: $color-blue-dark;
                    }
                }

            }

            &--no-banner {
                color: $color-blue-dark;
            }

            > h6 {
                font-size: 12px;
                text-transform: uppercase;

                @media (max-width: 1024px) {
                    font-size: 10px;
                }
            }

            > h3 {
                margin-bottom: 62px;
                font-size: 40px;
                text-shadow: 3px 3px $color-grey-dark;

                @media (max-width: 1024px) {
                    font-size: 25px;
                }
            }
        }
    }

    &__links {
        width: 100%;
        margin-top: 30px;
        display: flex;
        max-width: 1144px;
        font-family: $font-open-sans;
        font-size: 14px;
        letter-spacing: 0.42px;
        color: $color-grey;

        > * {
            color: $color-grey;
        }

        &--current {
            color: $color-blue;
            overflow-wrap: normal;
        }
    }

    .products-list-screen {
        &__order-by {
            justify-content: flex-end;
        }
    }

    &__selectors {
        display: flex;
        align-items: flex-start;
        width: 100%;
        max-width: 1144px;
        margin-top: 45px;
        font-family: $font-open-sans-bold;
        color: $color-blue-dark;
        padding: 0 15px;

        @media (max-width: 900px) {
            width: 100vw;
            display: grid;
            grid-template-columns: 33% 33% 33%;
        }

        .sort-filter-button {
            margin-right: 28px;

            @media (max-width: 900px) {
                margin-right: 0;
                justify-self: center;
            }

            > div {
                border: none;
                min-width: 198px;
                height: 56px;
                font-family: $font-open-sans-semibold;
                font-size: 20px;

                @media (max-width: 900px) {
                    font-family: $font-open-sans;
                    font-size: 15px;
                }

                @media (max-width: 600px) {
                    min-width: 120px;
                    height: 36px;
                    font-family: $font-open-sans;
                    font-size: 12px;
                }

                > div {
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }

            &__active {
                background-color: rgba($color-blue, 0.1);
                color: $color-blue;
            }

            &__inactive {
                background-color: $color-blue;
                color: #ffffff;
            }
        }

        > h6 {
            margin: 0;
            margin-right: 15px;
            font-size: 14px;
        }

        > div {
            margin-right: 7px;
        }
    }

    &__contacts {
        width: 100%;
        max-width: 1144px;
        margin-top: 40px;
        margin-bottom: 80px;
        padding: 0 15px;

        @media (max-width: 1024px) {
            width: 90vw;
        }

        &__features {

            &--mobile {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 auto;

                &__item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 90%;
                    text-align: center;
                    margin-left: 1em;
                    margin-bottom: 2em;

                    &__icon-container {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 20%;
                        box-shadow: 5px 5px 15px $color-shadow;
                        padding: 1em;
                        height: 90px;
                        width: 90px;
                    }

                    &__text-container {
                        text-align: left;
                        margin-left: 1em;
                        > h2 {
                            font-family: $font-open-sans-bold;
                            padding-top: .5em;
                            color: $color-blue-dark;
                            font-size: 1rem;
                        }

                        > h3 {
                            font-family: $font-open-sans-semibold;
                            color: $color-grey;
                            font-size: .8rem;
                            padding-top: .5em;
                        }
                    }
                }
            }

            &--desktop {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                margin: 0 auto;
                width: 1144px;

                &__item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 190px;
                    text-align: center;
                    margin: 32px;

                    > h2 {
                        width: 200px;
                        font-family: $font-open-sans-bold;
                        padding-top: 24px;
                        color: $color-blue-dark;
                        font-size: 20px;
                        letter-spacing: .6px;
                    }

                    > h3 {
                        width: 300px;
                        font-family: $font-open-sans-semibold;
                        color: $color-grey;
                        font-size: 16px;
                        padding-top: 11px;
                    }

                    &__icon-container {
                        width: 120px;
                        height: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 20%;
                        box-shadow: 5px 5px 15px $color-shadow;

                        > img {
                            width: 67px;
                            height: 67px;
                        }
                    }
                }
            }
        }

        .leaflet-container {
            width: 100%;
            max-width: 371px;
            height: 164px;
        }

        &__info-container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            &__content {
                width: 300px;
                margin-top: 5px;
                margin-right: 20px;

                > div {
                    margin-top: 25px;

                    > hr {
                        border: none;
                        margin: 15px 0;
                    }

                    > p {
                        margin: 0;
                        font-family: $font-open-sans;
                        font-size: 20px;
                        color: $color-grey;
                        text-align: left;

                        &.contact-fee-info {
                            font-size: 14px;
                            font-family: $font-open-sans-light;
                        }

                        @media (max-width: 1024px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    &__history {
        margin-bottom: 81px;

        .tagline {
            max-width: 1114px;
            overflow: hidden;
            text-overflow: ellipsis;

            @media (max-width: 1024px) {
                > h3 {
                    font-size: 20px;
                    margin-bottom: 30px;
                }

                > p {
                    font-size: 15px;
                    width: 90vw;
                }
            }
        }

        &__product-button {
            display: flex;
            justify-content: center;
            width: 100%;

            > button {
                display: flex;
                justify-content: center;
                width: 195px;
                height: 56px;

                @media (max-width: 1024px) {
                    font-family: $font-open-sans;
                }
            }
        }

        &__small-gallery {
            margin-top: 50px;
            display: flex;
            flex-direction: column;

            &__text {
                max-width: 90vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                word-break: break-word;

                > h3 {
                    font-family: $font-open-sans-bold;
                    font-size: 35px;
                    color: $color-blue-dark;
                    margin-bottom: 30px;
                }

                > p {
                    font-family: $font-open-sans;
                    color: $color-grey;
                    text-align: justify;
                    white-space: pre-line;
                }
            }

            &__photos {
                width: 90vw;
                height: 400px;
                display: grid;
                grid-template-columns: 50% 50%;

                > div {
                    background-size: cover;
                    border-radius: 10px;
                    margin: 3px 3px 10px;
                    box-shadow: 5px 5px 5px $color-light-grey;
                }

                > :nth-child(1) {
                    grid-column: 1;
                    grid-row: span 2;
                }

                > :nth-child(4) {
                    grid-column: 2;
                    grid-row: 2 / span 2;
                }
            }
        }

        &__gallery {
            display: flex;
            margin-top: 40px;
            width: 1112px;
            justify-content: space-between;

            &__alt-photos-container {
                margin-top: 40px;
                display: flex;
                justify-content: space-around;
                width: 1100px;

                > img {
                    border-radius: 20px;
                    box-shadow: 5px 5px 0 $color-shadow;
                    max-width: 270px;
                }
            }

            &__text-container {
                width: 357px;
                margin-top: 40px;
                margin-right: 20px;
                word-break: break-word;

                > h3 {
                    width: 100%;
                    grid-column: 1;
                    grid-row: 1 / span all;
                    text-align: center;
                    max-width: 332px;
                    margin-bottom: 30px;
                    font-family: $font-open-sans-bold;
                    font-size: 40px;
                    color: $color-blue-dark;
                }

                > p {
                    width: 100%;
                    font-family: $font-open-sans;
                    font-size: 16px;
                    color: $color-grey;
                    text-align: justify;
                    white-space: pre-line;
                }
            }

            &__photo-container {
                display: grid;
                grid-template-columns: 368px 364px;
                grid-template-rows: 226px 226px 228px;

                .short {
                    height: 212px;
                }

                .tall {
                    height: 454px;
                }

                > div {
                    width: 357px;
                    background-repeat: no-repeat;
                    background-position: top left;
                    background-size: cover;
                    border-radius: 10px;
                    background-attachment: scroll;
                }

                > :nth-child(1) {
                    grid-column: 1;
                    grid-row: 1;
                }

                > :nth-child(2) {
                    grid-column: 2;
                    grid-row: 1 / span 2;

                }

                > :nth-child(3) {
                    grid-column: 1;
                    grid-row: 2 / span 2;
                }

                > :nth-child(4) {
                    grid-column: 2;
                    grid-row: 3;
                    align-self: flex-end;
                }
            }
        }
    }
}